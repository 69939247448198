import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FaStar, FaEnvelope, FaPhoneAlt, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useLocation, useParams } from 'react-router-dom';
import { getBrokerbyID } from '../services/brokrService';
import { getPropertyBybrokerId } from '../services/listingService';
import LoaderCard from '../components/loaderCard';
import { GrLocation } from 'react-icons/gr';
import { IoBedOutline } from 'react-icons/io5';
import { LiaBathSolid, LiaMapMarkedAltSolid } from 'react-icons/lia';
import { BiArea } from 'react-icons/bi';
import Header from '../components/header';
import { Tooltip } from 'react-tooltip';
import { CiViewList } from 'react-icons/ci';
import MapComponent from '../components/map';
import { useTranslation } from 'react-i18next';
import { brokerEngData } from './brokerEngData';
import { brokersFrData } from './brokerFrData';
import { FaLinkedin } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { RiFacebookBoxFill } from "react-icons/ri";
import { IoLogoYoutube } from "react-icons/io";
import ScrollToTop from '../components/scroll-top';
import MapView from './mapView';


const IndividualBroker: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [broker, setBroker] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [brokerListings, setBrokerListings] = useState<any[]>([])
    const [listloading, setListloading] = useState(false)
    const itemsPerPage = 8;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [countResult, setCountResult] = useState(0)
    const [currentViewBroker, setCurrentViewBroker] = useState('list')
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    // const location = useLocation();


    const { i18n } = useTranslation();


    const language = i18n.language

    // const currentPath = location.pathname;
    useEffect(() => {
        document.title = t('title.brokers');
    }, [t])

    useEffect(() => {
        if (i18n.language === 'fr') {
            getFrBroker();
        } else {
            getBroker()
        }
    }, [i18n.language]);

    useEffect(() => {
        if (broker.CODE) {
            getlistingByBroker(broker.CODE, currentPage);
        }
    }, [currentPage, broker.CODE, currentViewBroker]);









    const getFrBroker = async () => {
        // console.log("check1");
        setLoading(true);
        try {
            const [prenom, nom]: any = id?.split('-');
            let capitalizedNom = nom.charAt(0).toUpperCase() + nom.slice(1);
            if(nom==='mckeown'){
                capitalizedNom = 'McKeown';
            }
            //   console.log(id);

            const brokerArray = brokersFrData.filter((b) => b.NOM === capitalizedNom);
            setBroker(brokerArray[0]);
            getlistingByBroker(brokerArray[0].CODE, 1);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };



    // console.log(brokerListings)

    const getBroker = async () => {
        // console.log("check2");

        setLoading(true);
        try {
            const [prenom, nom]: any = id?.split('-');
            let capitalizedNom = nom.charAt(0).toUpperCase() + nom.slice(1);
            if(nom==='mckeown'){
                capitalizedNom = 'McKeown';
            }
            const brokerArray = brokerEngData.filter((b) => b.NOM === capitalizedNom);
            // console.log(brokerArray);

            setBroker(brokerArray[0]);
            getlistingByBroker(brokerArray[0].CODE, 1);

        } catch (err) {
            console.error("Error fetching broker data:", err);
        } finally {
            setLoading(false);
        }
    };


    const getlistingByBroker = async (codeId: any, pageNumber: number) => {
        let bro = pageNumber;
        if (currentViewBroker === 'list') {
            bro = pageNumber;
        } else {
            bro = 0;
        }
        setListloading(true);
        try {
            const response = await getPropertyBybrokerId(codeId, currentViewBroker === 'map' ? 0 : itemsPerPage, bro);
            // console.log(response);
            setBrokerListings(response.data.inscriptions);
            setTotalPages(response.data.total_pages);
            setCountResult(response.data.total_count);
        } catch (err) {
            console.log(err);
        } finally {
            setListloading(false);
        }
    };






    const formatPrice = (price: any) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        }).format(price);
    };
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = brokerListings.slice(indexOfFirstItem, indexOfLastItem);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            const nextPage = currentPage + 1;
            getlistingByBroker(broker.CODE, nextPage);
            setCurrentPage(nextPage);
            window.scrollTo({ top: 0, behavior: 'smooth' });


        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            const prevPage = currentPage - 1;
            getlistingByBroker(broker.CODE, prevPage);
            setCurrentPage(prevPage);
            window.scrollTo({ top: 0, behavior: 'smooth' });


        }
    };

    const handlePageClick = (pageNumber: number) => {
        getlistingByBroker(broker.CODE, pageNumber);
        setCurrentPage(pageNumber);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    const updateHeading = (text: string) => {
        const format = text.replace(/(?<!^)(È)/g, 'é')
            .replace(/‡/g, "à")

            .replace(/È/g, 'é')
            .replace(/è/g, 'é')
            .replace(/Ë/g, 'ë')
            .replace(/¿/g, 'À')
            // .replace(/!/g, "")
            .replace(/Î/g, "î")
            .replace(/Í/g, "ê")
            .replace(/\*\*/g, '')
            .replace(/Ó/g, "ô")         //
            .replace(/Œ/g, "œ")         // Replace 'Œ' with 'œ' ∞
            .replace(/‰/g, "é")
            .replace(/\.\./g, '')
            .replace(/∞/g, "")
            .replace(/\u2026/g, 'É')
            .replace(/\I\D\:/g, "")
            .replace(/Ù/g, "ô")
            // Replace 'Œ' with 'œ' ù Ù
            .replace(/ë/g, 'è')
            .replace(/˚/g, "û")
            .replace(/˘/g, "ù")
        return format;
    }




    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPagesToShow = 5;
        const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);


        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    className={`w-12 rounded-full text-sm ${i === currentPage ? 'bg-pantone288-700 hover:bg-yellow-500 text-white' : 'bg-gray-200'}`}
                    onClick={() => handlePageClick(i)}
                >
                    {i}
                </button>
            );
        }



        return pageNumbers;
    };

    if (loading) {
        return <div>Loading....</div>;
    }




    return (
        <>
            <Header />
            <div className="flex flex-col items-center md:py-8 py-2 bg-gray-100 md:pt-36 pt-24">
                {/* Broker Card */}
                <motion.div
                    className="flex flex-col md:flex-row w-full bg-white shadow-lg rounded-lg overflow-hidden"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                >
                    {/* Broker Image */}
                    <div className="md:w-1/3 p-4">
                        <img
                            src={broker.PHOTO_URL}
                            alt={`${broker.PRENOM} ${broker.NOM}`}
                            className="rounded-lg w-full object-cover" />
                    </div>

                    {/* Broker Details */}
                    <div className="md:w-2/3 p-6">
                        <div className="flex items-center">
                            <h1 className="text-2xl font-bold text-gray-800">
                                {broker.PRENOM} {broker.NOM}
                            </h1>
                        </div>

                        <p className="text-lg font-medium text-gray-600 text-start">
                            {broker.TITRE_PROFESSIONNEL || 'Real Estate Broker'}
                        </p>

                        {/* Broker Information */}
                        <div className="mt-4 space-y-2 text-start">
                            {/* <p className="text-gray-700">
                    <span className="font-semibold">{t('broker.agent')}: </span> {broker.NO_CERTIFICAT}
                </p> */}
                            <p className="text-gray-700">
                                <span className="font-semibold">{t('broker.phone')}: </span> {broker.TELEPHONE_1 ? broker.TELEPHONE_1 : ''}
                            </p>
                            <p className="text-gray-700">
                                <span className="font-semibold">{t('broker.email')}: </span> {broker.COURRIEL ? broker.COURRIEL : ''}
                            </p>
                        </div>
                        <div className="mt-3">
                            <p className="text-gray-700 text-start"><span className="font-semibold">{t('broker.desc')}: </span>{broker.description}</p>
                        </div>

                        {/* Action Buttons */}
                        <div className="mt-6 flex flex-wrap gap-4 justify-center lg:justify-start  w-full">
                            <a
                                href={`mailto:${broker.COURRIEL}`}
                                className="flex items-center justify-center px-4 py-2 border rounded-lg text-green-500 border-green-500 hover:bg-green-500 w-full sm:w-auto"
                            >
                                <FaEnvelope className="mr-2" /> {t('broker.send')}
                            </a>
                            <a
                                href={`tel:${broker.TELEPHONE_1}`}
                                className="flex items-center justify-center px-4 py-2 border rounded-lg text-green-500 border-green-500 hover:bg-green-500 w-full sm:w-auto"
                            >
                                <FaPhoneAlt className="mr-2" /> {t('broker.call')}
                            </a>

                            {broker.INSTA && (
                                <a
                                    href={`${broker.INSTA}`}  target="_blank"
                                    className="flex items-center justify-center px-4 py-2 border rounded-lg text-green-500 border-green-500 hover:bg-green-500 w-full sm:w-auto"
                                >
                                    <RiInstagramFill size={22} className="mr-2" /> Instagram
                                </a>
                            )}

                            {broker.LINKEDIN && (
                                <a
                                    href={`${broker.LINKEDIN}`}  target="_blank"
                                    className="flex items-center justify-center px-4 py-2 border rounded-lg text-green-500 border-green-500 hover:bg-green-500 w-full sm:w-auto"
                                >
                                    <FaLinkedin size={20} className="mr-2" /> LinkedIn
                                </a>
                            )}

                            {broker.FB && (
                                <a
                                    href={`${broker.FB}`}  target="_blank"
                                    className="flex items-center justify-center px-4 py-2 border rounded-lg text-green-500 border-green-500 hover:bg-green-500 w-full sm:w-auto"
                                >
                                    <RiFacebookBoxFill size={22} className="mr-2" /> Facebook
                                </a>
                            )}

                            {broker.YT && (
                                <a
                                    href={`${broker.YT}`}  target="_blank"
                                    className="flex items-center justify-center px-4 py-2 border rounded-lg text-green-500 border-green-500 hover:bg-green-500 w-full sm:w-auto"
                                >
                                    <IoLogoYoutube size={20} className="mr-2" /> Youtube
                                </a>
                            )}
                        </div>
                    </div>
                </motion.div>

                <div className="p-4 flex justify-end w-full">
                    <div className='flex gap-2'>
                        <div className='px-2 py-2 border border-gray-300 rounded-md flex items-center cursor-pointer gap-1' onClick={() => {
                            setCurrentViewBroker(currentViewBroker === 'list' ? 'map' : 'list')
                        }}>
                            {currentViewBroker === 'list' ?
                                <div className='flex gap-1'>
                                    <LiaMapMarkedAltSolid size={22} />
                                    <p>{t('broker.map')}</p>
                                </div> :
                                <div className='flex gap-1'>
                                    <CiViewList size={22} />
                                    <p>{t('broker.list')}</p>
                                </div>}
                        </div >
                    </div>
                </div>

                {currentViewBroker === 'list' && (
                    <>
                        <div className='grid gap-y-16 grid-cols-4 gap-7 p-7 max-sm:grid-cols-1 max-md:grid-cols-2 xl:grid-cols-3 xl:pt-16 xl2:grid-cols-4 xl2:pt-6 max-lg:grid-cols-2 2xl:grid-cols-4 max-xl:grid-cols-3 place-items-center'>
                            {listloading ? (
                                Array.from({ length: itemsPerPage }).map((_, index) => (
                                    <LoaderCard key={index} />
                                ))
                            ) : (
                                brokerListings.map((item: any, index: number) => (
                                    <div key={index} className="w-[370px] max-2xl:w-[330px] max-sm:w-full rounded-xl shadow-lg">
                                        <div className="h-[500px] max-2xl:h-[400px] relative bg-cover bg-center rounded-2xl overflow-hidden">
                                            <img
                                                src={item.photos[0]}
                                                alt="home"
                                                className="rounded-2xl w-full h-full cursor-pointer object-cover transform hover:scale-105"
                                                style={{
                                                    transition: 'all 1s cubic-bezier(.3,1,.35,1) 0s, transform .5s ease',
                                                }}
                                                onClick={() => window.open(`/${language}/listings.php?mls=${item.NO_INSCRIPTION}`, '_blank')}
                                            />

                                            <div className="p-4 rounded-b-xl absolute top-[64%] w-full">

                                                <div className="w-full bg-white px-5 py-3 flex flex-col rounded-xl gap-2 text-sm h-28 bg-opacity-80">
                                                    <div className="flex justify-between items-center">
                                                        <h2
                                                            className="text-lg text-yellow-500 font-bold whitespace-nowrap"
                                                        // data-tooltip-id="my-tooltip"
                                                        // data-tooltip-content={item.listPrice}
                                                        >
                                                            {formatPrice(
                                                                item.PRIX_LOCATION_DEMANDE
                                                                    ? item.PRIX_LOCATION_DEMANDE
                                                                    : item.PRIX_DEMANDE
                                                            )}
                                                        </h2>

                                                        <p className="text-xs text-gray-600 font-semibold">
                                                            {item.class}
                                                        </p>
                                                    </div>
                                                    <div className="flex gap-1 items-center">
                                                        <GrLocation size={22} className="shrink-0" />
                                                        <h2
                                                            className="text-sm overflow-hidden text-ellipsis text-start font-normal whitespace-nowrap"
                                                            data-tooltip-id={`my-tooltip-${index}`}
                                                            data-tooltip-content={`${item.NO_CIVIQUE_DEBUT ? item.NO_CIVIQUE_DEBUT + ", " : ""}${updateHeading(item.NOM_RUE_COMPLET)}, ${item.municipalities}, ${item.CODE_POSTAL}`}
                                                        >
                                                            {`${item.NO_CIVIQUE_DEBUT ? item.NO_CIVIQUE_DEBUT + ", " : ""}${updateHeading(item.NOM_RUE_COMPLET)}, ${item.municipalities}, ${item.CODE_POSTAL}`}
                                                        </h2>


                                                        <Tooltip id={`my-tooltip-${index}`} className='z-30' />
                                                    </div>
                                                    <div className="flex items-center justify-between">
                                                        <div className="flex items-center gap-1 ">
                                                            {[
                                                                item.NB_CHAMBRES && (
                                                                    <div key="bedrooms" className="flex gap-1 items-center">
                                                                        <IoBedOutline size={25} />
                                                                        <h4>{item.NB_CHAMBRES}</h4>
                                                                    </div>
                                                                ),
                                                                item.NB_SALLES_BAINS && (
                                                                    <div key="bathrooms" className="flex gap-1 items-center">
                                                                        <LiaBathSolid size={25} />
                                                                        <h4>{item.NB_SALLES_BAINS}</h4>
                                                                    </div>
                                                                ),
                                                                item.FACADE_TERRAIN && item.PROFONDEUR_TERRAIN && (
                                                                    <div key="land" className="flex gap-1 items-center">
                                                                        <BiArea size={25} />
                                                                        <h4 className="text-sm truncate w-32">
                                                                            {`${item.FACADE_TERRAIN}m x ${item.PROFONDEUR_TERRAIN}m | ${item.SUPERFICIE_TERRAIN} m²`}
                                                                        </h4>
                                                                    </div>
                                                                ),
                                                            ]
                                                                .filter(Boolean)
                                                                .map((element, index, array) => (
                                                                    <React.Fragment key={index}>
                                                                        {element}
                                                                        {index < array.length - 1 && <span className="text-gray-300">|</span>}
                                                                    </React.Fragment>
                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))

                            )}
                        </div>
                    </>
                )}

                {currentViewBroker === 'map' && (
                    <div className='w-full h-full'>
                        <MapView data={brokerListings} />
                    </div>
                )
                }


            </div>

            {(brokerListings.length > 0 && !loading && totalPages > 1 && currentViewBroker === 'list') && (
                <div className="flex lg:gap-5 justify-center mt-5 lg:p-5 p-2 gap-2">
                    <button
                        className="bg-pantone288-700 hover:bg-yellow-500 hover:text-white p-2 lg:p-4 rounded-full disabled:opacity-50"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                    >
                        <FaArrowLeft color='#ffffff' />
                    </button>
                    {renderPageNumbers()}
                    <button
                        className="bg-pantone288-700 hover:bg-yellow-500 hover:text-white lg:p-4 p-2 rounded-full disabled:opacity-50"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        <FaArrowRight color='#ffffff' />
                    </button>
                </div>
            )}

        </>
    );
};

export default IndividualBroker;




