import React, { useContext, useEffect, useState } from 'react';
import logo from '../assets/images/IMT_logo_coul.webp';
import { FilterContext } from "../context/filterContext";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { MdLogout } from 'react-icons/md';
import LoginDialog from './LoginDialog';
import LogoutConfirmationPopup from './logoutConfirmationPopup';


const Footer: React.FC = () => {
    const { collections, setCollections } = useContext(FilterContext);
    const { t } = useTranslation();
    const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
    const [scrollbarWidth, setScrollbarWidth] = useState(0);
    const user = Cookies.get('userDetails') ? JSON.parse(Cookies.get('userDetails') as string) : null;

    const openLoginDialog = () => {
        setIsLoginDialogOpen(true);
    };

    const closeLoginDialog = () => {
        setIsLoginDialogOpen(false);
    };

    const logOut = () => {
        Cookies.remove('userDetails');
        setShowProfileMenu(false)
        setIsSidebarOpen(false);
        setShowLogoutConfirmation(false);

    }

    const logOutConfirm = () => {
        setShowLogoutConfirmation(!showLogoutConfirmation);
        setShowProfileMenu(false)
        setIsSidebarOpen(false);
    }

    const calculateScrollbarWidth = () => {
        if (window.innerWidth > document.documentElement.clientWidth) {
            const scrollDiv = document.createElement('div');
            scrollDiv.style.overflow = 'scroll';
            scrollDiv.style.width = '100px';
            scrollDiv.style.height = '100px';
            scrollDiv.style.visibility = 'hidden';
            document.body.appendChild(scrollDiv);
            const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
            document.body.removeChild(scrollDiv);
            return scrollbarWidth;
        }
        return 0; // No scrollbar
    };

    useEffect(() => {
      const updateScrollbarWidth = () => {
          setScrollbarWidth(calculateScrollbarWidth());
      };
    
      // Initial calculation
      updateScrollbarWidth();
    
      // Update on window resize
      window.addEventListener('resize', updateScrollbarWidth);
    
      // Cleanup listener on unmount
      return () => {
          window.removeEventListener('resize', updateScrollbarWidth);
      };
    }, []);
    
    useEffect(() => {
      // Add or remove the class from the body element
      if (isSidebarOpen ) {
          document.body.classList.add('overflow-hidden');
      } else {
          document.body.classList.remove('overflow-hidden');
      }
    
      // Cleanup on unmount
      return () => {
          document.body.classList.remove('overflow-hidden');
      };
    }, [isSidebarOpen]);

    
    const { i18n } = useTranslation();

    const language = i18n.language;
    return (
        <>
            <footer className="bg-gray-100 py-6 px-4  md:py-8 lg:px-28">
                <div className="max-w-screen-xl  md:text-start text-center  items-center flex flex-col md:flex-row md:justify-between md:space-x-8">
                    <div className='text-start mb-6 md:mb-0' >
                        <a href="/">
                            <img src={logo} alt="Logo" className="mb-4 w-44 md:w-44" />
                        </a>
                    </div>
                    {/* <div className="cursor-pointer gap-1 flex flex-col items-start mb-8 lg:mb-0 " > */}
                    {/* <img src={logo} alt='logo' width={100} className="w-40 md:w-40 lg:w-40   " /> */}
                    {/* <img src={leftLogo} alt='logo' width={100} className="w-44 md:w-44 lg:w-44 rounded-sm " /> */}
                    {/* </div> */}

                    <div className="space-y-2 md:text-start text-center mb-6 md:mb-0">
                        <ul className="space-y-1">
                            <li><a href={`/${language}/collections`} className="hover:text-blue-600">{t("footerData.collections")}</a></li>
                            <li><a href={`/${language}/listings`} className="hover:text-blue-600">{t("footerData.properties")}</a></li>
                            <li><a href={`/${language}/rentals`} className="hover:text-blue-600">{t("footerData.rentals")}</a></li>
                            <li><a href={`/${language}/brokers`} className="hover:text-blue-600">{t("footerData.brokers")}</a></li>
                            <li><a href={`/${language}/about`} className="hover:text-blue-600">{t("footerData.about")}</a></li>
                            <li><a href={`/${language}/contact`} className="hover:text-blue-600">{t("footerData.contact")}</a></li>
                        </ul>
                    </div>

                    <div className="space-y-2 md:text-start text-center mb-6 md:mb-0">
                        <ul className="space-y-1">
                            <li>
                                <a
                                    className="hover:text-blue-600 cursor-pointer"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const url = new URL(window.location.origin + `/${language}/listings`);
                                        url.searchParams.append('collections', 'exclusives');
                                        window.location.href = url.toString();
                                    }}
                                >
                                    {t("footerData.exclusive")}
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-blue-600 cursor-pointer"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const url = new URL(window.location.origin + `/${language}/listings`);
                                        url.searchParams.append('collections', 'maisons-signature');
                                        window.location.href = url.toString();
                                    }}
                                >
                                    {t("footerData.signature")}
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-blue-600 cursor-pointer"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const url = new URL(window.location.origin + `/${language}/listings`);
                                        url.searchParams.append('collections', 'maisons-de-campagne');
                                        window.location.href = url.toString();
                                    }}
                                >
                                    {t("footerData.country")}
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-blue-600 cursor-pointer"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const url = new URL(window.location.origin + `/${language}/listings`);
                                        url.searchParams.append('collections', 'proprietes-riveraines');
                                        window.location.href = url.toString();
                                    }}
                                >
                                    {t("footerData.waterfront")}
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-blue-600 cursor-pointer"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const url = new URL(window.location.origin + `/${language}/listings`);
                                        url.searchParams.append('collections', 'condos-regionaux');
                                        window.location.href = url.toString();
                                    }}
                                >
                                    {t("footerData.regional")}
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-blue-600 cursor-pointer"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const url = new URL(window.location.origin + `/${language}/listings`);
                                        url.searchParams.append('collections', 'pres-golf');
                                        window.location.href = url.toString();
                                    }}
                                >
                                    {t("footerData.mountain")}
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="space-y-2 md:text-start text-center mb-6 md:mb-0">
                        <ul className="space-y-1">
                            <li>
                                <a
                                    className="hover:text-blue-600 cursor-pointer"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const url = new URL(window.location.origin + `/${language}/listings`);
                                        url.searchParams.append('collections', 'centre-de-villegiature');
                                        window.location.href = url.toString();
                                    }}
                                >
                                    {t("footerData.pedestrian")}
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-blue-600 cursor-pointer"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const url = new URL(window.location.origin + `/${language}/listings`);
                                        url.searchParams.append('collections', 'ski-in-ski-out');
                                        window.location.href = url.toString();
                                    }}
                                >
                                    {t("footerData.mountainD")}
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-blue-600 cursor-pointer"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const url = new URL(window.location.origin + `/${language}/listings`);
                                        url.searchParams.append('collections', 'terrains-et-projets');
                                        window.location.href = url.toString();
                                    }}
                                >
                                    {t("footerData.project")}
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-blue-600 cursor-pointer"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const url = new URL(window.location.origin + `/${language}/listings`);
                                        url.searchParams.append('collections', 'commercial');
                                        window.location.href = url.toString();
                                    }}
                                >
                                    {t("footerData.commercial")}
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-blue-600 cursor-pointer"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const url = new URL(window.location.origin + `/${language}/listings`);
                                        url.searchParams.append('collections', 'locations-saisonnieres');
                                        window.location.href = url.toString();
                                    }}
                                >
                                    {t("footerData.seasonal")}
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-blue-600 cursor-pointer"
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     const url = new URL(window.location.origin + '/global');
                                    //     url.searchParams.append('collections', 'international-properties');
                                    //     window.location.href = url.toString();
                                    // }}
                                    href={`/${language}/global-luxury-search`}
                                    target='_blank'
                                >
                                    {t("footerData.international")}
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="md:text-start text-center flex flex-col items-center gap-8  lg:items-start">
                        {/* <div className='md:block hidden relative'>
                    {user ? (
                      <div >
                      <div className=' flex items-center justify-end mr-3  '>
                        <img src={user.photoUrl} alt="user" className="w-10 h-10 rounded-full cursor-pointer z-50"
                          onClick={() => setShowProfileMenu(!showProfileMenu)} />
                      </div>
                        <div className=''>
                          {showProfileMenu && (
                            <div className='  absolute bg-white p-3 shadow-2xl rounded-lg z-30'>
                              <a className='block text-gray-800 hover:text-gray-500 text-start  p-2'>
                                <CgProfile className='inline-block mr-2' />
                                {t('profile.00')}</a>
                              <a className='block text-gray-800 hover:text-gray-500 text-start p-2'>
                                <FaRegHeart className='inline-block mr-2' />
                                {t('profile.01')}</a>
                              <div className=' text-Red-600 flex items-center hover:text-Red-600/80 text-start px-2 cursor-pointer w-full' onClick={() => logOutConfirm()}>
                                <MdLogout className=' inline-block mr-2' />
                                <p className='inline-block' >{t('profile.02')}</p>
                                </div>
                            </div>
                          )}
                        </div>
                        </div>
            
                    ) : (
                      <button className=" px-4 py-2 mr-2 bg-pantone288-500 border-yellow-500 border rounded-full hover:bg-white hover:text-yellow-500 text-white font-semibold text-sm transition duration-300"
                        onClick={() => openLoginDialog()}>
                        {t('signup.05')}
                      </button>
                    )
                    }
                    </div> */}
                        <p>2195, chemin du Village,<br />
                            Mont-Tremblant, Québec, J8E 3M3<br />
                            T: 1 (819) 425-9324<br />
                            <a href="mailto:info@mtre.ca" className="text-blue-600">info@mtre.ca</a>
                        </p>
                    
                    </div>
                </div>

            </footer>
            <div className='bg-white'>
                <div className='max-w-screen-xl mx-auto text-start py-4 px-4'>
                    <p className="font-light">
                        © {t('footerData.c')}
                    </p>
                    <a className="text-blue-600 underline cursor-pointer"
                        onClick={() => window.open(`/${language}/legal-notice`, "_blank")}
                    >{t("footerData.legal")}</a>
                </div>
            </div>
            
                    {/* <LoginDialog isOpen={isLoginDialogOpen} onClose={closeLoginDialog} />

{ showLogoutConfirmation &&

<div className='absolute z-30  h-full  flex items-center justify-center'>
<LogoutConfirmationPopup
    onConfirm={logOut}
    onCancel={logOutConfirm}
  />
</div>
} */}

        </>
    );
};

export default Footer;
