import Jennifer from '../assets/images/brokers/jennifer-mckeown.webp'
import Chauvin from '../assets/images/brokers/ann-chauvin.webp'
import Pratte from '../assets/images/brokers/yves.pratte.webp'
import Olney from '../assets/images/brokers/gladys_olney.webp'
import Richardson from '../assets/images/brokers/kim_richardson.webp'
import Dalbec from '../assets/images/brokers/paul.dalbec.webp'
import LÈonard from '../assets/images/brokers/Louise Leonard (1).webp'
import Jeffreys from '../assets/images/brokers/ann_jeffreys.webp'
import Maillet from '../assets/images/brokers/caroline-maillet.webp'
import Enoaie from '../assets/images/brokers/Corina Enoaie (1).webp'
import Boon from '../assets/images/brokers/emy_boon.webp'
import Couchman from '../assets/images/brokers/sean.couchman.webp'
import Martineau from '../assets/images/brokers/emma_martineau.webp'
import Bota from '../assets/images/brokers/myriane-bota.webp'
import Simard from '../assets/images/brokers/ksimard.webp'
// import Balthazard from '../assets/images/brokers/laurier-2021.jpg'
import Naud from '../assets/images/brokers/myriam-naud.webp'
// import Bauer from '../assets/images/brokers/jo-bauer.webp'
import Ouimet from '../assets/images/brokers/Pierre Ouimet.webp'
import Griffin from '../assets/images/brokers/cynthia-griffin.webp'
import Lambert from '../assets/images/brokers/Veronique Lambert (1).webp'
import Philippe from '../assets/images/brokers/Philippe Genest.webp'



export const brokerEngData = [
    {
      "CODE": "59807",
      "BUR_CODE": "ITT001",
      "NO_CERTIFICAT": "B5489",
      "TYPE_CERTIFICAT": "CIADA",
      "NOM": "McKeown",
      "PRENOM": "Jennifer",
      "TITRE_PROFESSIONNEL": "Chartered Real Estate Broker",
      "CHAMP_INUTILISE_1": "",
      "TELEPHONE_1": "1 (819) 425-0074",
      "TELEPHONE_2": "8194259324",
      "TELEPHONE_FAX": "8194253640",
      "COURRIEL": "jmckeown@mtre.ca",
      "INSTA":"https://www.instagram.com/jennifermckeown123/",
      "LINKEDIN":"https://www.linkedin.com/in/jennifer-mckeown-a701032/overlay/about-this-profile/",
      "FB":"https://www.facebook.com/jennifermckeowntremblantrealestate",
      "SITE_WEB": "http://www.tremblantrealestate.ca",
      "CHAMP_INUTILISE_2": "",
      "CODE_LANGUE": "A",
      "PHOTO_URL": Jennifer,
      "DATE_MODIFICATION": "2024/10/09 18:07:35",
      "NOM_SOCIETE": "Jennifer McKeown Immobilier inc.",
      "TYPE_SOCIETE_DESC_F": "",
      "TYPE_SOCIETE_DESC_A": "",
      "LIEN_VIDEO_F": "",
      "LIEN_VIDEO_A": "",
      "PRESENTATION_F": "",
      "PRESENTATION_A": "\r",
      "description":"Jennifer McKeown has enjoyed a remarkable career as one of the regions top listing and selling agents thanks to her close ties to the founders-club of elite purchasers who helped establish Mont Tremblant as Eastern North America’s #1 resort. Her endeavors include a range of successful pursuits in real estate, including strategic planning of the initial Mont Tremblant resort. Jennifer founded Mont Tremblant Real Estate in 1996 to handle the ever-increasing demand for a professional regional real estate company in Mont Tremblant. Since then, she and her team have conducted several hundred million dollars in transactions within the region. Developers and individuals alike depend on Jennifer’s unique blend of knowledge, creativity and experience when buying or selling within the luxury market. With expertise on buying habits and selling expectations, she consistently delivers valuable, incomparable guidance to her clients. Jennifer’s uncompromising taste, amicable personality and intense devotion to her clients continuously sets the pace at Mont Tremblant Real Estate.",
      "company_info": {
        "CODE": "ITT001",
        "FIRME_CODE": "ITT",
        "NOM_LEGAL": "SIEGE SOCIAL",
        "NO_CIVIQUE": "2195",
        "NOM_RUE": "Ch. du Village",
        "BUREAU": "",
        "MUNICIPALITE": "Mont-Tremblant",
        "PROVINCE": "QC",
        "CODE_POSTAL": "J8E3M3",
        "TELEPHONE_1": "8194259324",
        "POSTE_1": "",
        "TELEPHONE_2": "8194253777",
        "POSTE_2": "",
        "TELEPHONE_FAX": "8194253640",
        "COURRIEL": "info@mtre.ca",
        "SITE_WEB": "https://www.tremblantrealestate.ca/",
        "DIRECTEUR_CODE": "59807",
        "URL_LOGO_BUREAU": "https://mediaserver.centris.ca/media.ashx?id=ADDE1A2DDDDD61CDDDB0842DD1&t=b&w=200&h=150"
      }
    },
    {
      "CODE": "65108",
      "BUR_CODE": "ITT001",
      "NO_CERTIFICAT": "B2906",
      "TYPE_CERTIFICAT": "AF",
      "NOM": "Chauvin",
      "PRENOM": "Ann",
      "TITRE_PROFESSIONNEL": "Real Estate Broker",
      "CHAMP_INUTILISE_1": "",
      "TELEPHONE_1": "1 (819) 425-4089",
      "TELEPHONE_2": "8194253777",
      "TELEPHONE_FAX": "",
      "COURRIEL": "achauvin@mtre.ca",
      "SITE_WEB": "http://www.royallepagetremblant.com",
      "CHAMP_INUTILISE_2": "",
      "CODE_LANGUE": "A",
      "PHOTO_URL": Chauvin,
      "DATE_MODIFICATION": "2024/10/09 18:07:35",
      "NOM_SOCIETE": "",
      "TYPE_SOCIETE_DESC_F": "",
      "TYPE_SOCIETE_DESC_A": "",
      "LIEN_VIDEO_F": "",
      "LIEN_VIDEO_A": "",
      "PRESENTATION_F": "",
      "PRESENTATION_A": "\r",
      "description":"I have practiced real estate for over 25 years and have enjoyed working with clients from many countries and provinces in Canada. I love finding the perfect home for my clients and have had many clients thank me for my professionalism. I have also been successful in finding clients townhouses or condos especially in the Nansenhus area. Many of these clients have come to me when they have decided to sell and I have worked hard to make their sale go smoothly. Real estate has grown in the Mont Tremblant area and now many people want to own homes in the area to take part in the biking, hiking, Ironman activities as well as the skiing. I will continue to work hard to find clients their dream property!",
      "company_info": {
        "CODE": "ITT001",
        "FIRME_CODE": "ITT",
        "NOM_LEGAL": "SIEGE SOCIAL",
        "NO_CIVIQUE": "2195",
        "NOM_RUE": "Ch. du Village",
        "BUREAU": "",
        "MUNICIPALITE": "Mont-Tremblant",
        "PROVINCE": "QC",
        "CODE_POSTAL": "J8E3M3",
        "TELEPHONE_1": "8194259324",
        "POSTE_1": "",
        "TELEPHONE_2": "8194253777",
        "POSTE_2": "",
        "TELEPHONE_FAX": "8194253640",
        "COURRIEL": "info@mtre.ca",
        "SITE_WEB": "https://www.tremblantrealestate.ca/",
        "DIRECTEUR_CODE": "59807",
        "URL_LOGO_BUREAU": "https://mediaserver.centris.ca/media.ashx?id=ADDE1A2DDDDD61CDDDB0842DD1&t=b&w=200&h=150"
      }
    },
    {
      "CODE": "77026",
      "BUR_CODE": "ITT001",
      "NO_CERTIFICAT": "C5532",
      "TYPE_CERTIFICAT": "AF",
      "NOM": "Pratte",
      "PRENOM": "Yves",
      "TITRE_PROFESSIONNEL": "Real Estate Broker",
      "CHAMP_INUTILISE_1": "",
      "TELEPHONE_1": "1 (819) 425-0827",
      "TELEPHONE_2": "",
      "TELEPHONE_FAX": "",
      "COURRIEL": "ypratte@mtre.ca",
      "LINKEDIN":"https://www.linkedin.com/in/yves-pratte-000a8959/",
      "SITE_WEB": "",
      "CHAMP_INUTILISE_2": "",
      "CODE_LANGUE": "F",
      "PHOTO_URL": Pratte,
      "DATE_MODIFICATION": "2024/10/09 18:07:35",
      "NOM_SOCIETE": "",
      "TYPE_SOCIETE_DESC_F": "",
      "TYPE_SOCIETE_DESC_A": "",
      "LIEN_VIDEO_F": "",
      "LIEN_VIDEO_A": "",
      "PRESENTATION_F": "",
      "PRESENTATION_A": "\r",
      "description":"Passionate about the Laurentians, Yves has lived in Tremblant since 1981 to make the most of the outdoor activities of the region. As an enthusiast of downhill and cross-country skiing, fat biking, mountain and road biking, he loves action sports especially when he shares them with his four boys. In business, as in sport, Yves likes to outdo himself. Real estate broker at Tremblant for 23 years, he leads his career in the same way. His vision is to make a difference in people’s lives. Both professionally and personally, his primary goal is to add value to everything he undertakes. That’s one of the reasons he builds lasting relationships allowing him to work primarily by referrals. Throughout his many years in the industry, Yves has gained extensive real estate expertise in condos, waterfront homes, cottages, land, and investment properties for the vast region of the Laurentians.A graduate of UQAM in marketing, he offers a tailored approach to the needs of his customers. For him, he is result-driven which stems from his excellent customer service, integrity and enthusiasm. He likes to promise a lot but deliver more!",
      "company_info": {
        "CODE": "ITT001",
        "FIRME_CODE": "ITT",
        "NOM_LEGAL": "SIEGE SOCIAL",
        "NO_CIVIQUE": "2195",
        "NOM_RUE": "Ch. du Village",
        "BUREAU": "",
        "MUNICIPALITE": "Mont-Tremblant",
        "PROVINCE": "QC",
        "CODE_POSTAL": "J8E3M3",
        "TELEPHONE_1": "8194259324",
        "POSTE_1": "",
        "TELEPHONE_2": "8194253777",
        "POSTE_2": "",
        "TELEPHONE_FAX": "8194253640",
        "COURRIEL": "info@mtre.ca",
        "SITE_WEB": "https://www.tremblantrealestate.ca/",
        "DIRECTEUR_CODE": "59807",
        "URL_LOGO_BUREAU": "https://mediaserver.centris.ca/media.ashx?id=ADDE1A2DDDDD61CDDDB0842DD1&t=b&w=200&h=150"
      }
    },
    {
      "CODE": "77799",
      "BUR_CODE": "ITT001",
      "NO_CERTIFICAT": "C6923",
      "TYPE_CERTIFICAT": "AF",
      "NOM": "Olney",
      "PRENOM": "Gladys",
      "TITRE_PROFESSIONNEL": "Real Estate Broker",
      "CHAMP_INUTILISE_1": "",
      "TELEPHONE_1": "1 (819) 425-4654",
      "TELEPHONE_2": "8194259324",
      "TELEPHONE_FAX": "",
      "COURRIEL": "gladysolney@mtre.ca",
      "INSTA":"https://www.instagram.com/lada_lovegladys/",
      "LINKEDIN":"https://www.linkedin.com/in/gladys-olney-5846157/overlay/about-this-profile/",
      "FB":"https://www.facebook.com/olneyagent/",
      "SITE_WEB": "",
      "CHAMP_INUTILISE_2": "",
      "CODE_LANGUE": "A",
      "PHOTO_URL": Olney,
      "DATE_MODIFICATION": "2024/10/09 18:07:35",
      "NOM_SOCIETE": "",
      "TYPE_SOCIETE_DESC_F": "",
      "TYPE_SOCIETE_DESC_A": "",
      "LIEN_VIDEO_F": "",
      "LIEN_VIDEO_A": "",
      "PRESENTATION_F": "",
      "PRESENTATION_A": "\r",
      "description":"Representing the region of Mont-Tremblant homeowners and future homeowners, Gladys is committed to listening to her clients’ needs and utilizing her keen negotiating skills to ensure a successful transaction. As a resident of Mont-Tremblant for nearly 25 years, Gladys understands the numerous intangible benefits of life in this beautiful region, with its unmatched natural landscape, internationally renowned ski & golf resort, and its wide array of winter and summer activities and festivals. Gladys came to the area in 1995 where she planted her roots and raised her family. She has concluded hundreds of successful transactions since she joined the Real Estate profession in 1998 and has left myriad happy clients in her wake.",
      "company_info": {
        "CODE": "ITT001",
        "FIRME_CODE": "ITT",
        "NOM_LEGAL": "SIEGE SOCIAL",
        "NO_CIVIQUE": "2195",
        "NOM_RUE": "Ch. du Village",
        "BUREAU": "",
        "MUNICIPALITE": "Mont-Tremblant",
        "PROVINCE": "QC",
        "CODE_POSTAL": "J8E3M3",
        "TELEPHONE_1": "8194259324",
        "POSTE_1": "",
        "TELEPHONE_2": "8194253777",
        "POSTE_2": "",
        "TELEPHONE_FAX": "8194253640",
        "COURRIEL": "info@mtre.ca",
        "SITE_WEB": "https://www.tremblantrealestate.ca/",
        "DIRECTEUR_CODE": "59807",
        "URL_LOGO_BUREAU": "https://mediaserver.centris.ca/media.ashx?id=ADDE1A2DDDDD61CDDDB0842DD1&t=b&w=200&h=150"
      }
    },
    {
      "CODE": "78734",
      "BUR_CODE": "ITT001",
      "NO_CERTIFICAT": "C8103",
      "TYPE_CERTIFICAT": "AF",
      "NOM": "Richardson",
      "PRENOM": "Kim",
      "TITRE_PROFESSIONNEL": "Real Estate Broker",
      "CHAMP_INUTILISE_1": "",
      "TELEPHONE_1": "1 (819) 425-4836",
      "TELEPHONE_2": "8194259324",
      "TELEPHONE_FAX": "",
      "COURRIEL": "krichardson@mtre.ca",
      "INSTA":"https://www.instagram.com/yourpartnersinrealestate/",
      "LINKEDIN":"https://www.linkedin.com/in/kim-richardson-53472458/overlay/about-this-profile/",
      "FB":"https://www.facebook.com/VosPart",
      "SITE_WEB": "http://www.yourpartnersinrealestate.com",
      "CHAMP_INUTILISE_2": "",
      "CODE_LANGUE": "A",
      "PHOTO_URL": Richardson,
      "DATE_MODIFICATION": "2024/10/09 18:07:35",
      "NOM_SOCIETE": "",
      "TYPE_SOCIETE_DESC_F": "",
      "TYPE_SOCIETE_DESC_A": "",
      "LIEN_VIDEO_F": "",
      "LIEN_VIDEO_A": "",
      "PRESENTATION_F": "",
      "PRESENTATION_A": "\r",
      "description":"Kim is a knowledgeable residential/commercial real estate broker with over 20 years of experience. Prior to receiving her OCAIQ license she worked for the Intrawest Development Corp. and also had an extensive career in media and conference sales with Southex, Industrial Consumer Shows and Rawlco Communications. Kim understands the resort’s real estate market and is very familiar with Tremblant and the surrounding region. Her team, Your Partners in Real Estate has been consistently designated the Red Diamond reward for Royal Lepage and has been awarded the prestigious Chairman’s Club. Her instincts will give you invaluable insight as to what constitutes a good investment and which properties and/or areas will best fit your requirements. Kim is professional, courteous and very comfortable to work with. She can help guide you skillfully through the sales process whether it be listing or purchasing. The team carries an extensive inventory and has an expansive referral and client base. They are strong marketers as well as excellent sales brokers. Please do not hesitate to contact Kim and her team at Your Partners in Real Estate for all your real estate needs.",
      "company_info": {
        "CODE": "ITT001",
        "FIRME_CODE": "ITT",
        "NOM_LEGAL": "SIEGE SOCIAL",
        "NO_CIVIQUE": "2195",
        "NOM_RUE": "Ch. du Village",
        "BUREAU": "",
        "MUNICIPALITE": "Mont-Tremblant",
        "PROVINCE": "QC",
        "CODE_POSTAL": "J8E3M3",
        "TELEPHONE_1": "8194259324",
        "POSTE_1": "",
        "TELEPHONE_2": "8194253777",
        "POSTE_2": "",
        "TELEPHONE_FAX": "8194253640",
        "COURRIEL": "info@mtre.ca",
        "SITE_WEB": "https://www.tremblantrealestate.ca/",
        "DIRECTEUR_CODE": "59807",
        "URL_LOGO_BUREAU": "https://mediaserver.centris.ca/media.ashx?id=ADDE1A2DDDDD61CDDDB0842DD1&t=b&w=200&h=150"
      }
    },
    {
      "CODE": "80189",
      "BUR_CODE": "ITT001",
      "NO_CERTIFICAT": "C2585",
      "TYPE_CERTIFICAT": "CIADA",
      "NOM": "Dalbec",
      "PRENOM": "Paul",
      "TITRE_PROFESSIONNEL": "Chartered Real Estate Broker",
      "CHAMP_INUTILISE_1": "",
      "TELEPHONE_1": "1 (819) 425-4008",
      "TELEPHONE_2": "8194253777",
      "TELEPHONE_FAX": "",
      "COURRIEL": "pdalbec@mtre.ca",
      "INSTA":"https://www.instagram.com/pauldalbec/",
      "LINKEDIN":"https://www.linkedin.com/in/pauldalbec/overlay/about-this-profile/",
      "FB":"https://www.facebook.com/dalbecbalthazard/",
      "SITE_WEB": "http://www.tremblantrealestate.ca",
      "CHAMP_INUTILISE_2": "",
      "CODE_LANGUE": "F",
      "PHOTO_URL": Dalbec,
      "DATE_MODIFICATION": "2024/10/09 18:07:35",
      "NOM_SOCIETE": "Paul Dalbec Immobilier inc.",
      "TYPE_SOCIETE_DESC_F": "",
      "TYPE_SOCIETE_DESC_A": "",
      "LIEN_VIDEO_F": "",
      "LIEN_VIDEO_A": "",
      "PRESENTATION_F": "",
      "PRESENTATION_A": "\r",
      "description":"Have you ever wondered: Who’s ‘that guy’ on the “For Sale” signs we see all over town? That guy is Paul Dalbec, a lifetime local and Tremblant-based broker who for the past 30 years has been finding and selling unique, life changing properties for his clients. This man, who is a natural and active member of the community, is not only passionate about real estate sales, but also about life, skiing and the outdoors. For 20 years, he has been in the top 25 Royal Lepage brokers in Quebec and 6 times in the Top 10 of this group. He has also owned Royal Lepage Mont-Tremblant region since 1998. In April 2018, Royal Lepage Mont-Tremblant merged with Les Immeubles Mont-Tremblant to become the largest agency in Mont-Tremblant. So, what’s Paul’s secret? Tireless vitality, a willingness to embrace change, and Paul’s ongoing search for new ways to love his job every day. By creating a bond of trust with his buyers and sellers, by providing them with valuable advice, Paul ensures that every transaction goes smoothly. He builds relationships, not transactions. Paul has distinguished himself over the years in the art of negotiation. He is committed to negociate the best agreements and conditions based on your specific needs. With his dedication and attention to detail he will meet your expectations and even try to overcome them. If you’re looking for quality, integrity and an honest broker who’ll always have your back, look no further than “that guy” on the signs around town. Paul Dalbec is the man of the situation.",
      "company_info": {
        "CODE": "ITT001",
        "FIRME_CODE": "ITT",
        "NOM_LEGAL": "SIEGE SOCIAL",
        "NO_CIVIQUE": "2195",
        "NOM_RUE": "Ch. du Village",
        "BUREAU": "",
        "MUNICIPALITE": "Mont-Tremblant",
        "PROVINCE": "QC",
        "CODE_POSTAL": "J8E3M3",
        "TELEPHONE_1": "8194259324",
        "POSTE_1": "",
        "TELEPHONE_2": "8194253777",
        "POSTE_2": "",
        "TELEPHONE_FAX": "8194253640",
        "COURRIEL": "info@mtre.ca",
        "SITE_WEB": "https://www.tremblantrealestate.ca/",
        "DIRECTEUR_CODE": "59807",
        "URL_LOGO_BUREAU": "https://mediaserver.centris.ca/media.ashx?id=ADDE1A2DDDDD61CDDDB0842DD1&t=b&w=200&h=150"
      }
    },
    {
      "CODE": "83557",
      "BUR_CODE": "ITT001",
      "NO_CERTIFICAT": "D3868",
      "TYPE_CERTIFICAT": "AF",
      "NOM": "Léonard",
      "PRENOM": "Louise",
      "TITRE_PROFESSIONNEL": "Real Estate Broker",
      "CHAMP_INUTILISE_1": "",
      "TELEPHONE_1": "819-681-6955",
      "TELEPHONE_2": "8194259324",
      "TELEPHONE_FAX": "8194253640",
      "COURRIEL": "louisel@mtre.ca",
      "INSTA":"https://www.instagram.com/louiseleonardcourtier/",
      "LINKEDIN":"https://www.linkedin.com/in/louise-l%C3%A9onard-42975713a/overlay/about-this-profile/",
      "FB":"https://www.facebook.com/monttremblantimmobilier/",
      "SITE_WEB": "http://www.tremblantrealestate.ca",
      "CHAMP_INUTILISE_2": "",
      "CODE_LANGUE": "F",
      "PHOTO_URL": LÈonard,
      "DATE_MODIFICATION": "2024/10/09 18:07:35",
      "NOM_SOCIETE": "",
      "TYPE_SOCIETE_DESC_F": "",
      "TYPE_SOCIETE_DESC_A": "",
      "LIEN_VIDEO_F": "",
      "LIEN_VIDEO_A": "",
      "PRESENTATION_F": "",
      "PRESENTATION_A": "\r",
      "description":"Native of the Laurentians, I worked for over 20 years in the sector of sales in Ontario, in the pharmaceutical field and I also owned a transportation company. Back in Québec, I have been practicing as a real estate broker in the Upper Laurentians since 2004. I specialize in ‘waterfront’ properties. I offer a bilingual and professional service. If you are looking for a broker to listen to you and help you sell and/or buy, do not hesitate to contact me.",
      "company_info": {
        "CODE": "ITT001",
        "FIRME_CODE": "ITT",
        "NOM_LEGAL": "SIEGE SOCIAL",
        "NO_CIVIQUE": "2195",
        "NOM_RUE": "Ch. du Village",
        "BUREAU": "",
        "MUNICIPALITE": "Mont-Tremblant",
        "PROVINCE": "QC",
        "CODE_POSTAL": "J8E3M3",
        "TELEPHONE_1": "8194259324",
        "POSTE_1": "",
        "TELEPHONE_2": "8194253777",
        "POSTE_2": "",
        "TELEPHONE_FAX": "8194253640",
        "COURRIEL": "info@mtre.ca",
        "SITE_WEB": "https://www.tremblantrealestate.ca/",
        "DIRECTEUR_CODE": "59807",
        "URL_LOGO_BUREAU": "https://mediaserver.centris.ca/media.ashx?id=ADDE1A2DDDDD61CDDDB0842DD1&t=b&w=200&h=150"
      }
    },
    {
      "CODE": "84130",
      "BUR_CODE": "ITT001",
      "NO_CERTIFICAT": "D4626",
      "TYPE_CERTIFICAT": "CIADA",
      "NOM": "Jeffreys",
      "PRENOM": "Ann",
      "TITRE_PROFESSIONNEL": "Chartered Real Estate Broker",
      "CHAMP_INUTILISE_1": "",
      "TELEPHONE_1": "1 (819) 425-1524",
      "TELEPHONE_2": "8194259324",
      "TELEPHONE_FAX": "",
      "COURRIEL": "ajeffreys@mtre.ca",
      "INSTA":"https://www.instagram.com/yourpartnersinrealestate/",
      "FB":"https://www.facebook.com/VosPart",
      "SITE_WEB": "http://www.annjeffreys.com",
      "CHAMP_INUTILISE_2": "",
      "CODE_LANGUE": "A",
      "PHOTO_URL": Jeffreys,
      "DATE_MODIFICATION": "2024/10/09 18:07:35",
      "NOM_SOCIETE": "",
      "TYPE_SOCIETE_DESC_F": "",
      "TYPE_SOCIETE_DESC_A": "",
      "LIEN_VIDEO_F": "",
      "LIEN_VIDEO_A": "",
      "PRESENTATION_F": "",
      "PRESENTATION_A": "\r",
      "description":"Born and raised in Mont-Tremblant and the third generation of local realtors in her family, Ann has over 15 years of experience in Laurentian real estate sales. As a chartered broker, Ann has obtained the highest level of real estate training available in order to facilitate smooth transactions for buyers and sellers alike., Prior to embarking on her real estate career, Ann was an account director for an advertising agency, responsible for major resort and real estate development accounts, as well as Group Sales Manager for Tremblant Resort. Her involvement included strategic planning of communications, marketing and sales campaigns, as well as product analysis and development. Together with her Partners in Real Estate, Kim Richardson and Emma Martineau, they strive to exceed their clients’ expectations and ensure smooth real estate transactions from start to finish.",
      "company_info": {
        "CODE": "ITT001",
        "FIRME_CODE": "ITT",
        "NOM_LEGAL": "SIEGE SOCIAL",
        "NO_CIVIQUE": "2195",
        "NOM_RUE": "Ch. du Village",
        "BUREAU": "",
        "MUNICIPALITE": "Mont-Tremblant",
        "PROVINCE": "QC",
        "CODE_POSTAL": "J8E3M3",
        "TELEPHONE_1": "8194259324",
        "POSTE_1": "",
        "TELEPHONE_2": "8194253777",
        "POSTE_2": "",
        "TELEPHONE_FAX": "8194253640",
        "COURRIEL": "info@mtre.ca",
        "SITE_WEB": "https://www.tremblantrealestate.ca/",
        "DIRECTEUR_CODE": "59807",
        "URL_LOGO_BUREAU": "https://mediaserver.centris.ca/media.ashx?id=ADDE1A2DDDDD61CDDDB0842DD1&t=b&w=200&h=150"
      }
    },
    {
        "CODE": "94545",
        "BUR_CODE": "ITT001",
        "NO_CERTIFICAT": "E4911",
        "TYPE_CERTIFICAT": "AF",
        "NOM": "Maillet",
        "PRENOM": "Caroline",
        "TITRE_PROFESSIONNEL": "Real Estate Broker",
        "CHAMP_INUTILISE_1": "",
        "TELEPHONE_1": "1 (819) 808-9404",
        "TELEPHONE_2": "8144259324",
        "TELEPHONE_FAX": "8194253640",
        "COURRIEL": "cmaillet@mtre.ca",
        "INSTA":"https://www.instagram.com/caroline.maillet.immobilier/",
        "LINKEDIN":"https://www.linkedin.com/in/carolinemailletrealtor/overlay/about-this-profile/",
        "FB":"https://www.facebook.com/carolinemailletrealestate",
        "SITE_WEB": "http://www.carolinemaillet.com",
        "CHAMP_INUTILISE_2": "",
        "CODE_LANGUE": "F",
        "PHOTO_URL": Maillet,
        "DATE_MODIFICATION": "2024/10/09 18:07:35",
        "NOM_SOCIETE": "",
        "TYPE_SOCIETE_DESC_F": "",
        "TYPE_SOCIETE_DESC_A": "",
        "LIEN_VIDEO_F": "",
        "LIEN_VIDEO_A": "",
        "PRESENTATION_F": "",
        "PRESENTATION_A": "\r",
        "description":"Caroline has been part of Mont-Tremblant Real Estate team since 2010 and Realtor since 2008. Coming from an extensive background in sales this career choice has led Caroline to become a driving-force in the Tremblant real estate market. Previously, she assumed the title of Sales Manager for a successful real estate promoter in the Tremblant area. Her responsibilities included expanding the buyers market to overseas clientele. This was achieved by participating in several international shows with the outcome of over $12,000,000 in sales over two years! Her clientele now ranges from those looking for principal residences, secondary homes, condominiums, land and commercial properties. Her extensive knowledge has also helped in establishing a large clientele for income properties. Caroline is a good listener and takes the time to place her client’s needs first then discover the right property. Caroline is very connected. She has established an extensive network that includes local and overseas clients, investors, buyers and sellers. Her thorough knowledge of the market, her negotiation skills and her extensive knowledge of non-resident by laws all combine to offer a very professional bilingual real estate agent who is ready and prepared to service all real estate requests. 2018 recipient award President Gold Palm Royal Lepage.",
        "company_info": {
          "CODE": "ITT001",
          "FIRME_CODE": "ITT",
          "NOM_LEGAL": "SIEGE SOCIAL",
          "NO_CIVIQUE": "2195",
          "NOM_RUE": "Ch. du Village",
          "BUREAU": "",
          "MUNICIPALITE": "Mont-Tremblant",
          "PROVINCE": "QC",
          "CODE_POSTAL": "J8E3M3",
          "TELEPHONE_1": "8194259324",
          "POSTE_1": "",
          "TELEPHONE_2": "8194253777",
          "POSTE_2": "",
          "TELEPHONE_FAX": "8194253640",
          "COURRIEL": "info@mtre.ca",
          "SITE_WEB": "https://www.tremblantrealestate.ca/",
          "DIRECTEUR_CODE": "59807",
          "URL_LOGO_BUREAU": "https://mediaserver.centris.ca/media.ashx?id=ADDE1A2DDDDD61CDDDB0842DD1&t=b&w=200&h=150"
        }
      },
      {
        "CODE": "103627",
        "BUR_CODE": "ITT001",
        "NO_CERTIFICAT": "G1851",
        "TYPE_CERTIFICAT": "AF",
        "NOM": "Enoaie",
        "PRENOM": "Corina",
        "TITRE_PROFESSIONNEL": "Real Estate Broker | Director",
        "CHAMP_INUTILISE_1": "",
        "TELEPHONE_1": "1 (819) 421-0373",
        "TELEPHONE_2": "8194259324",
        "TELEPHONE_FAX": "",
        "COURRIEL": "ecorina@mtre.ca",
        "INSTA":"https://www.instagram.com/corina_mont_tremblant/",
        "LINKEDIN":"https://www.linkedin.com/in/corina-enoaie-7a52b534/overlay/about-this-profile/",
        "FB":"https://www.facebook.com/ecorinaimmobiliertremblant",
        "YT":"https://www.youtube.com/channel/UCXbsnXIKfuA5K0vaVfez53Q/videos",
        "SITE_WEB": "https://tremblant.realestate",
        "CHAMP_INUTILISE_2": "",
        "CODE_LANGUE": "F",
        "PHOTO_URL": Enoaie,
        "DATE_MODIFICATION": "2024/10/09 18:07:35",
        "NOM_SOCIETE": "Corina Enoaie Immobilier Inc.",
        "TYPE_SOCIETE_DESC_F": "SociÈtÈ par actions d'un courtier immobilier rÈsidentiel et commercial",
        "TYPE_SOCIETE_DESC_A": "Business corporation owned by a Residential and Commercial Real Estate Broker",
        "LIEN_VIDEO_F": "",
        "LIEN_VIDEO_A": "",
        "PRESENTATION_F": "Depuis l'‚ge de 18 ans, j'ai travaillÈ dans la vente, contribuant au commerce familial. AprËs avoir embrassÈ une carriËre enrichissante dans l'industrie du ski en tant que monitrice, superviseur et directrice d'une Ècole de ski, j'ai finalement trouvÈ ma ",
        "PRESENTATION_A": "Since the age of 18, I have been working in sales, contributing to the family business. After embracing a fulfilling career in the ski industry as an instructor, supervisor, and ski school director, I finally found my calling in real estate in 2011, and i",
        "description":"Mont-Tremblant, International Ski & Golf resort Real Estate is one of Corina’s strong points. In Real Estate since 2011, Corina is a very determined broker. She achieves great success helping people buy or sell one of the most important investments of their life and makes it a positive and pleasant experience. Continuing education is one of Corina’s greatest qualities. In an area such as real estate where technology evolves at such a high speed, she understands that staying updated makes a difference for her customers and herself. Helping people in achieving their real estate goals in a relaxed and honest way, Corina will “actually listen” to her clients. She will take the necessary time in order to truly know and understand their expectations. Corina says “the part I enjoy the most in Real Estate is … everything! From meeting with my clients, to the paper work and to the showings. Every day is an adventure I enjoy with a big smile on my face!”. You can rely on Corina when you have questions or concerns about Real Estate: if she does not know the answer, she will find it for you.",
        "company_info": {
          "CODE": "ITT001",
          "FIRME_CODE": "ITT",
          "NOM_LEGAL": "SIEGE SOCIAL",
          "NO_CIVIQUE": "2195",
          "NOM_RUE": "Ch. du Village",
          "BUREAU": "",
          "MUNICIPALITE": "Mont-Tremblant",
          "PROVINCE": "QC",
          "CODE_POSTAL": "J8E3M3",
          "TELEPHONE_1": "8194259324",
          "POSTE_1": "",
          "TELEPHONE_2": "8194253777",
          "POSTE_2": "",
          "TELEPHONE_FAX": "8194253640",
          "COURRIEL": "info@mtre.ca",
          "SITE_WEB": "https://www.tremblantrealestate.ca/",
          "DIRECTEUR_CODE": "59807",
          "URL_LOGO_BUREAU": "https://mediaserver.centris.ca/media.ashx?id=ADDE1A2DDDDD61CDDDB0842DD1&t=b&w=200&h=150"
        }
      },
      {
        "CODE": "114119",
        "BUR_CODE": "ITT001",
        "NO_CERTIFICAT": "G9909",
        "TYPE_CERTIFICAT": "AF",
        "NOM": "Boon",
        "PRENOM": "Emy",
        "TITRE_PROFESSIONNEL": "Real Estate Broker",
        "CHAMP_INUTILISE_1": "",
        "TELEPHONE_1": "1 (819) 425-0565",
        "TELEPHONE_2": "8194253777",
        "TELEPHONE_FAX": "8194258884",
        "COURRIEL": "eboon@mtre.ca",
        "INSTA":"https://www.instagram.com/emyboon_immobilier/",
        "LINKEDIN":"https://www.linkedin.com/in/emy-boon-57811b150/overlay/about-this-profile/",
        "FB":"https://www.facebook.com/ImmobilierTremblant/",
        "SITE_WEB": "http://www.tremblantrealestate.ca/fr/",
        "CHAMP_INUTILISE_2": "",
        "CODE_LANGUE": "F",
        "PHOTO_URL": Boon,
        "DATE_MODIFICATION": "2024/10/09 18:07:35",
        "NOM_SOCIETE": "Emy Boon Immobilier Inc.",
        "TYPE_SOCIETE_DESC_F": "",
        "TYPE_SOCIETE_DESC_A": "",
        "LIEN_VIDEO_F": "",
        "LIEN_VIDEO_A": "",
        "PRESENTATION_F": "…tant nÈe ‡ Tremblant, et exerÁant dans le domaine de l'immobilier depuis 2016, c'est un privilËge de faire dÈcouvrir notre belle rÈgion ‡ travers l'immobilier, que ce soit pour le ski, les vacances ou pour s'installer de faÁon permanente. Vous pourrez co",
        "PRESENTATION_A": "Being a native of Tremblant, and working in the real estate industry since 2016, it is a privilege to showcase our beautiful region through real estate, whether it be for skiing, vacationing or settling down permanently. You will see my unique perspective",
        "description":"As a lifelong resident of Mont-Tremblant, Emy Boon has acquired over the years, a great knowledge of her native region. Possessing a desire to have people discover the area as well as being very social, the choice of career quickly became clear: The world of real estate. A choice that allows her to create lasting relationships as well as sharing her passion for this beautiful region. It is in 2016 that she joins the Royal Lepage Région Mont-Tremblant team which will merge, in 2018, with Mont-Tremblant Real Estate. Over the years, she built a solid reputation and earned the respect of her colleagues and clients. In addition, Emy is fortunate to collaborate with experienced colleagues, some of whom have over 30 years of experience in the field. That your real estate project is to acquire a rental property; a second home or a permanent residence, Emy will accompany you and meet your needs. Indeed, her unique vision and knowledge of the well-hidden gems of this resort destination will seduce and reassure you.",
        "company_info": {
          "CODE": "ITT001",
          "FIRME_CODE": "ITT",
          "NOM_LEGAL": "SIEGE SOCIAL",
          "NO_CIVIQUE": "2195",
          "NOM_RUE": "Ch. du Village",
          "BUREAU": "",
          "MUNICIPALITE": "Mont-Tremblant",
          "PROVINCE": "QC",
          "CODE_POSTAL": "J8E3M3",
          "TELEPHONE_1": "8194259324",
          "POSTE_1": "",
          "TELEPHONE_2": "8194253777",
          "POSTE_2": "",
          "TELEPHONE_FAX": "8194253640",
          "COURRIEL": "info@mtre.ca",
          "SITE_WEB": "https://www.tremblantrealestate.ca/",
          "DIRECTEUR_CODE": "59807",
          "URL_LOGO_BUREAU": "https://mediaserver.centris.ca/media.ashx?id=ADDE1A2DDDDD61CDDDB0842DD1&t=b&w=200&h=150"
        }
      },
      {
        "CODE": "118613",
        "BUR_CODE": "ITT001",
        "NO_CERTIFICAT": "H2863",
        "TYPE_CERTIFICAT": "CRES",
        "NOM": "Couchman",
        "PRENOM": "Sean",
        "TITRE_PROFESSIONNEL": "Real Estate Broker",
        "CHAMP_INUTILISE_1": "",
        "TELEPHONE_1": "1 (819) 421-2390",
        "TELEPHONE_2": "",
        "TELEPHONE_FAX": "8194295425",
        "COURRIEL": "scouchman@mtre.ca",
        "INSTA":"https://www.instagram.com/seancouchman/",
        "LINKEDIN":"https://www.instagram.com/seancouchman/",
        "FB":"https://www.facebook.com/seancouchmanrealtor/",
        "SITE_WEB": "",
        "CHAMP_INUTILISE_2": "",
        "CODE_LANGUE": "A",
        "PHOTO_URL": Couchman,
        "DATE_MODIFICATION": "2024/10/09 18:07:35",
        "NOM_SOCIETE": "",
        "TYPE_SOCIETE_DESC_F": "",
        "TYPE_SOCIETE_DESC_A": "",
        "LIEN_VIDEO_F": "",
        "LIEN_VIDEO_A": "",
        "PRESENTATION_F": "",
        "PRESENTATION_A": "\r",
        "description":"As someone who has lived in the Mont Tremblant area for over 25 years, I can provide a unique perspective on what makes the region the perfect destination for a permanent or second home. With over a decade of experience in sales, marketing and customer service combined with core personal values based on professionalism, perseverance, honesty and respect, I will be priding myself on providing a real estate experience that will be efficient and enjoyable.",
        "company_info": {
          "CODE": "ITT001",
          "FIRME_CODE": "ITT",
          "NOM_LEGAL": "SIEGE SOCIAL",
          "NO_CIVIQUE": "2195",
          "NOM_RUE": "Ch. du Village",
          "BUREAU": "",
          "MUNICIPALITE": "Mont-Tremblant",
          "PROVINCE": "QC",
          "CODE_POSTAL": "J8E3M3",
          "TELEPHONE_1": "8194259324",
          "POSTE_1": "",
          "TELEPHONE_2": "8194253777",
          "POSTE_2": "",
          "TELEPHONE_FAX": "8194253640",
          "COURRIEL": "info@mtre.ca",
          "SITE_WEB": "https://www.tremblantrealestate.ca/",
          "DIRECTEUR_CODE": "59807",
          "URL_LOGO_BUREAU": "https://mediaserver.centris.ca/media.ashx?id=ADDE1A2DDDDD61CDDDB0842DD1&t=b&w=200&h=150"
        }
      },
      {
        "CODE": "118807",
        "BUR_CODE": "ITT001",
        "NO_CERTIFICAT": "H2899",
        "TYPE_CERTIFICAT": "CRES",
        "NOM": "Martineau",
        "PRENOM": "Emma",
        "TITRE_PROFESSIONNEL": "Residential Real Estate Broker",
        "CHAMP_INUTILISE_1": "",
        "TELEPHONE_1": "1 (819) 429-9106",
        "TELEPHONE_2": "8194259324",
        "TELEPHONE_FAX": "8194253640",
        "COURRIEL": "emartineau@mtre.ca",
        "INSTA":"https://www.instagram.com/yourpartnersinrealestate/",
        "LINKEDIN":"https://www.linkedin.com/in/emma-martineau-59215a115/overlay/about-this-profile/",
        "FB":"https://www.facebook.com/VosPart",
        "SITE_WEB": "",
        "CHAMP_INUTILISE_2": "",
        "CODE_LANGUE": "F",
        "PHOTO_URL": Martineau,
        "DATE_MODIFICATION": "2024/10/09 18:07:35",
        "NOM_SOCIETE": "",
        "TYPE_SOCIETE_DESC_F": "",
        "TYPE_SOCIETE_DESC_A": "",
        "LIEN_VIDEO_F": "",
        "LIEN_VIDEO_A": "",
        "PRESENTATION_F": "",
        "PRESENTATION_A": "\r",
        "description":"Emma Martineau is lucky enough to be born and raised in Mont-Tremblant, and brings her knowledge and love of the region into her career. Emma is the fifth generation of her family to live in the area and the fourth generation to work as a real estate broker. Her lineage provides the knowledge and experience to know what to expect and how to reach the goal. Before becoming a Real Estate Broker, Emma worked in marketing and sales during her 3 years living abroad in England. Upon returning to her home town of Mont-Tremblant in 2017, she was fortunate enough to become administrative assistant to Kim Richardson. In November 2018, Ann Jeffreys joined the team, and together they encouraged her to take the next steps in her career and to obtain her broker’s licence. Emma’s passion for the Laurentians has translated to a career, allowing her to help you discover everything this region has to offer. Whether you are looking to buy, sell or rent, with Emma and Your Partners in Real Estate, you can rest easy knowing that you are in good hands.",

      
      },
      {
        "CODE": "122120",
        "BUR_CODE": "ITT001",
        "NO_CERTIFICAT": "H4219",
        "TYPE_CERTIFICAT": "CRES",
        "NOM": "Bota",
        "PRENOM": "Myriane",
        "TITRE_PROFESSIONNEL": "Residential Real Estate Broker",
        "CHAMP_INUTILISE_1": "",
        "TELEPHONE_1": "1 (438) 998-2025",
        "TELEPHONE_2": "8194259324",
        "TELEPHONE_FAX": "8194253640",
        "COURRIEL": "mbota@mtre.ca",
        "INSTA":"https://www.instagram.com/bota.myriane/",
        "LINKEDIN":"https://ca.linkedin.com/in/myriane-bota-3b2a0b36?original_referer=https%3A%2F%2Fwww.google.com%2F",
        "FB":"https://www.facebook.com/people/Myriane-Bota/pfbid02AfcQbKLFEKg2wtR9NrpLo7gviaJiPKHkfk56rKtfkNVb3HEUEwQvHgNm2N8cxMk3l/?paipv=0&eav=AfY_HZNi2g6RL5_wzCrIyjK3gxiMvnjjfZf8c8TLqa1Rrm4gAQqbaX6rhbjI6j4Olto",
        "SITE_WEB": "http://tremblantrealestate.ca",
        "CHAMP_INUTILISE_2": "",
        "CODE_LANGUE": "F",
        "PHOTO_URL": Bota,
        "DATE_MODIFICATION": "2024/10/09 18:07:35",
        "NOM_SOCIETE": "",
        "TYPE_SOCIETE_DESC_F": "",
        "TYPE_SOCIETE_DESC_A": "",
        "LIEN_VIDEO_F": "",
        "LIEN_VIDEO_A": "",
        "PRESENTATION_F": "",
        "PRESENTATION_A": "\r",
        "description":"Myriane is a woman of action who likes to manage several projects at once. She has worked in the financial field for many years. She started as a foreign exchange and derivatives trader which led her to work in various locations including Europe. She then covered the large corporate market developing financing strategies and quickly chose to specialize in cash and liquidity management by proposing technological innovations to help her clients achieve their goals. Myriane has a bachelor’s degree in international finance from HEC and an MBA from Concordia University. She has been teaching finance at the university for the past 30 years at the undergraduate and graduate levels. She loves to be involved in the community, she was president of parents’ committees for ten years and manager of sport teams. Always looking for new challenges, it was after acquiring real estate in Tremblant that she got the bug for the field and began a new career as a broker. What could be better than to combine a passion in a bucolic place like the Laurentians where she can enjoy skiing and summer sports with her family. Myriane is dedicated and detail oriented, she has at heart the satisfaction of her clients and spares no time or energy to satisfy them. She considers it a privilege to work in partnership with her clients to help them achieve their goals.",
        "company_info": {
          "CODE": "ITT001",
          "FIRME_CODE": "ITT",
          "NOM_LEGAL": "SIEGE SOCIAL",
          "NO_CIVIQUE": "2195",
          "NOM_RUE": "Ch. du Village",
          "BUREAU": "",
          "MUNICIPALITE": "Mont-Tremblant",
          "PROVINCE": "QC",
          "CODE_POSTAL": "J8E3M3",
          "TELEPHONE_1": "8194259324",
          "POSTE_1": "",
          "TELEPHONE_2": "8194253777",
          "POSTE_2": "",
          "TELEPHONE_FAX": "8194253640",
          "COURRIEL": "info@mtre.ca",
          "SITE_WEB": "https://www.tremblantrealestate.ca/",
          "DIRECTEUR_CODE": "59807",
          "URL_LOGO_BUREAU": "https://mediaserver.centris.ca/media.ashx?id=ADDE1A2DDDDD61CDDDB0842DD1&t=b&w=200&h=150"
        }
      },
      {
        "CODE": "124251",
        "BUR_CODE": "ITT001",
        "NO_CERTIFICAT": "H3725",
        "TYPE_CERTIFICAT": "CRES",
        "NOM": "Simard",
        "PRENOM": "Katryna",
        "TITRE_PROFESSIONNEL": "Residential Real Estate Broker",
        "CHAMP_INUTILISE_1": "",
        "TELEPHONE_1": "1 (438) 509-4663",
        "TELEPHONE_2": "",
        "TELEPHONE_FAX": "",
        "COURRIEL": "ksimard@mtre.ca",
        "INSTA":"https://www.instagram.com/katryna.simard.courtier/",
        "LINKEDIN":"https://www.linkedin.com/in/katryna-simard-b0108b1a4/overlay/about-this-profile/",
        "FB":"https://www.facebook.com/people/Katryna-Simard-Courtier-immobilier-r%C3%A9sidentiel/100047313105314/",
        "SITE_WEB": "http://www.katrynasimard.com",
        "CHAMP_INUTILISE_2": "",
        "CODE_LANGUE": "F",
        "PHOTO_URL": Simard,
        "DATE_MODIFICATION": "2024/10/09 18:07:35",
        "NOM_SOCIETE": "Katryna Simard Inc.",
        "TYPE_SOCIETE_DESC_F": "SociÈtÈ par actions d'un courtier immobilier rÈsidentiel",
        "TYPE_SOCIETE_DESC_A": "Business corporation owned by a Residential Real Estate Broker",
        "LIEN_VIDEO_F": "",
        "LIEN_VIDEO_A": "",
        "PRESENTATION_F": "",
        "PRESENTATION_A": "\r",
        "description":"Katryna Simard is a passionate and established Real Estate Broker in the Mont-Tremblant region and surrounding areas. With over 15 years of experience in customer service, she has built a solid reputation by providing exceptional service to her clients. Since the beginning of her career in the industry, Katryna has always placed client satisfaction at the heart of her business. Through her personalized approach and attentive listening, she forges close relationships with her clients, earning their trust and loyalty. One of Katryna’s major assets is her in-depth knowledge of the Laurentian real estate market. She understands the unique characteristics of the region and market trends. This expertise allows her to accurately assess properties, set fair prices, and offer informed advice to her clients. Katryna knows how to effectively promote properties, utilizing innovative tools and leveraging her extensive professional network. When working with Katryna, clients can expect comprehensive and personalized support throughout the buying or selling process. She is present at every step, providing expert guidance, negotiating on behalf of her clients, and ensuring smooth transactions. Her commitment to excellence in customer service is reflected in the glowing testimonials she regularly receives from satisfied clients. Outside of her real estate career, Katryna is a warm, accessible, and passionate individual. She is known for her charismatic personality and her willingness to exceed her clients’ expectations. Her professionalism, integrity, and ability to build lasting relationships make her a preferred choice for anyone seeking an exceptional real estate experience in the Laurentians.",
        "company_info": {
          "CODE": "ITT001",
          "FIRME_CODE": "ITT",
          "NOM_LEGAL": "SIEGE SOCIAL",
          "NO_CIVIQUE": "2195",
          "NOM_RUE": "Ch. du Village",
          "BUREAU": "",
          "MUNICIPALITE": "Mont-Tremblant",
          "PROVINCE": "QC",
          "CODE_POSTAL": "J8E3M3",
          "TELEPHONE_1": "8194259324",
          "POSTE_1": "",
          "TELEPHONE_2": "8194253777",
          "POSTE_2": "",
          "TELEPHONE_FAX": "8194253640",
          "COURRIEL": "info@mtre.ca",
          "SITE_WEB": "https://www.tremblantrealestate.ca/",
          "DIRECTEUR_CODE": "59807",
          "URL_LOGO_BUREAU": "https://mediaserver.centris.ca/media.ashx?id=ADDE1A2DDDDD61CDDDB0842DD1&t=b&w=200&h=150"
        }
      },
      {
        "CODE": "127046",
        "BUR_CODE": "ITT001",
        "NO_CERTIFICAT": "H5511",
        "TYPE_CERTIFICAT": "CRES",
        "NOM": "Genest",
        "PRENOM": "Philippe",
        "TITRE_PROFESSIONNEL": "Residential Real Estate Broker",
        "CHAMP_INUTILISE_1": "",
        "TELEPHONE_1": "1 (514) 891-3827",
        "TELEPHONE_2": "8194253777",
        "TELEPHONE_FAX": "",
        "COURRIEL":"pgenest@mtre.ca",
        "INSTA":"https://www.instagram.com/philippe_genest/",
        "FB":"https://www.facebook.com/PhilippeGenestCourtierLaurentides?locale=fr_CA",
        // "LINKEDIN":"https://www.linkedin.com/in/laurierbalthazard/overlay/about-this-profile/",
        // "YT":"https://www.youtube.com/channel/UC5weAD8LoYuy8NglL-bwcsA",
        // "SITE_WEB": "https://www.tremblantrealestate.ca/",
        "CHAMP_INUTILISE_2": "",
        "CODE_LANGUE": "F",
        "PHOTO_URL": Philippe,
        "DATE_MODIFICATION": "2024/10/17 12:18:48",
        "NOM_SOCIETE": "",
        "TYPE_SOCIETE_DESC_F": "SociÈtÈ par actions d'un courtier immobilier rÈsidentiel",
        "TYPE_SOCIETE_DESC_A": "Business corporation owned by a Residential Real Estate Broker",
        "LIEN_VIDEO_F": "",
        "LIEN_VIDEO_A": "",
        "PRESENTATION_F": "",
        "PRESENTATION_A": "\r",
        "description":"With several years of experience in customer service, marketing and sales representation, Philippe will be able to offer you a service that meets your expectations! Ready to take on any challenge, he is constantly looking for innovation and new ways to optimize his service offering! His priority is always to offer you effective marketing strategies and the best visibility for all your projects! This is why Philippe joined Immeubles Mont-Tremblant in 2024. Each transaction is unique and Philippe will make sure to support you every step of the way. He is known for his listening skills, his dedication, his great availability and his ability to successfully complete a project. Real estate is part of his daily life and you will quickly see that he does his job with passion! Committed to providing personalized service and customer experience, he will be an advisor of choice to assist you in the purchase and sale of a property.",
        "company_info": {
          "CODE": "ITT001",
          "FIRME_CODE": "ITT",
          "NOM_LEGAL": "SIEGE SOCIAL",
          "NO_CIVIQUE": "2195",
          "NOM_RUE": "Ch. du Village",
          "BUREAU": "",
          "MUNICIPALITE": "Mont-Tremblant",
          "PROVINCE": "QC",
          "CODE_POSTAL": "J8E3M3",
          "TELEPHONE_1": "8194259324",
          "POSTE_1": "",
          "TELEPHONE_2": "8194253777",
          "POSTE_2": "",
          "TELEPHONE_FAX": "8194253640",
          "COURRIEL": "info@mtre.ca",
          "SITE_WEB": "https://www.tremblantrealestate.ca/",
          "DIRECTEUR_CODE": "59807",
          "URL_LOGO_BUREAU": "https://mediaserver.centris.ca/media.ashx?id=ADDE1A2DDDDD61CDDDB0842DD1&t=b&w=200&h=150"
        }
      },
      {
        "CODE": "131257",
        "BUR_CODE": "ITT001",
        "NO_CERTIFICAT": "J1072",
        "TYPE_CERTIFICAT": "CRES",
        "NOM": "Naud",
        "PRENOM": "Myriam",
        "TITRE_PROFESSIONNEL": "Residential Real Estate Broker",
        "CHAMP_INUTILISE_1": "",
        "TELEPHONE_1": "1 (819) 210-7879",
        "TELEPHONE_2": "",
        "TELEPHONE_FAX": "",
        "COURRIEL": "mnaud@mtre.ca",
        "INSTA":"https://www.instagram.com/myriam.naud_courtier_tremblant/",
        "LINKEDIN":"https://www.linkedin.com/in/myriam-naud-real-estate-agent-mont-tremblant-a98352274/overlay/about-this-profile/",
        "FB":"https://www.facebook.com/MNaudrealestate/",
        "SITE_WEB": "https://www.tremblantrealestate.ca/brokers/myriam-naud/",
        "CHAMP_INUTILISE_2": "",
        "CODE_LANGUE": "F",
        "PHOTO_URL": Naud,
        "DATE_MODIFICATION": "2024/10/09 18:07:35",
        "NOM_SOCIETE": "",
        "TYPE_SOCIETE_DESC_F": "",
        "TYPE_SOCIETE_DESC_A": "",
        "LIEN_VIDEO_F": "",
        "LIEN_VIDEO_A": "",
        "PRESENTATION_F": "",
        "PRESENTATION_A": "\r",
        "description":"With her extensive experience in managing diverse clienteles and highly distinctive projects, Myriam has developed an ability to build relationships based on integrity and transparency. Her great ability to care and her sensitivity distinguish her when it comes to targeting and understanding clients’ needs and objectives. For Myriam, the sale or purchase of a property requires great responsibility and savoir-faire. Myriam is proud to be part of Les Immeubles Mont-Tremblant, an agency established for 25 years, and to be surrounded by a solid team of experienced brokers. She wishes to put her passion for real estate, architecture and construction to the benefit of her clients and to contribute to the growth of Les Immeubles Mont-Tremblant. Originally from the Quebec City area, she fell in love with the region through the practice of various outdoor sports. Very active, Myriam has climbed numerous rock faces in Utah, Arizona, California and Western Canada. Kamouraska and the Montagne d’Argent (La Conception) are also among her favorites. It is not uncommon to see her mountain biking, hiking or backcountry skiing. Mont-Tremblant has provided her the perfect balance, thanks to all the offers and services that this magnificent region has to offer to define her LIFESTYLE. It is also this passion for the region and the sharing of her good addresses that she wants new buyers to discover. She recognizes that the road to closing a real estate transaction can sometimes be filled with emotions, sometimes very happy and sometimes more difficult. That is why you can always count on her discretion, integrity and impeccable reputation. She will make your experience harmonious, peaceful and enjoyable.",
        "company_info": {
          "CODE": "ITT001",
          "FIRME_CODE": "ITT",
          "NOM_LEGAL": "SIEGE SOCIAL",
          "NO_CIVIQUE": "2195",
          "NOM_RUE": "Ch. du Village",
          "BUREAU": "",
          "MUNICIPALITE": "Mont-Tremblant",
          "PROVINCE": "QC",
          "CODE_POSTAL": "J8E3M3",
          "TELEPHONE_1": "8194259324",
          "POSTE_1": "",
          "TELEPHONE_2": "8194253777",
          "POSTE_2": "",
          "TELEPHONE_FAX": "8194253640",
          "COURRIEL": "info@mtre.ca",
          "SITE_WEB": "https://www.tremblantrealestate.ca/",
          "DIRECTEUR_CODE": "59807",
          "URL_LOGO_BUREAU": "https://mediaserver.centris.ca/media.ashx?id=ADDE1A2DDDDD61CDDDB0842DD1&t=b&w=200&h=150"
        }
      },
      // {
      //   "CODE": "117917",
      //   "BUR_CODE": "EVT001",
      //   "NO_CERTIFICAT": "D1557",
      //   "TYPE_CERTIFICAT": "CRES",
      //   "NOM": "Bauer",
      //   "PRENOM": "Jan-Otto",
      //   "TITRE_PROFESSIONNEL": "Residential Real Estate Broker",
      //   "CHAMP_INUTILISE_1": "",
      //   "TELEPHONE_1": "1 (819) 430-8889",
      //   "TELEPHONE_2": "8004653700",
      //   "TELEPHONE_FAX": "",
      //   "COURRIEL": "jobauer@mtre.ca",
      //   "INSTA":"https://www.instagram.com/janottobauer.immobilier/",
      //   "LINKEDIN":"https://www.linkedin.com/in/jan-otto-bauer-926199105/overlay/about-this-profile/",
      //   "FB":"https://www.facebook.com/janottocourtierimmobilier",
      //   "SITE_WEB": "",
      //   "CHAMP_INUTILISE_2": "",
      //   "CODE_LANGUE": "F",
      //   "PHOTO_URL": Bauer,
      //   "DATE_MODIFICATION": "2024/08/14 11:29:41",
      //   "NOM_SOCIETE": "Gestion AndrÈ Parisien Inc.",
      //   "TYPE_SOCIETE_DESC_F": "SociÈtÈ par actions d'un courtier immobilier rÈsidentiel et commercial",
      //   "TYPE_SOCIETE_DESC_A": "Business corporation owned by a Residential and Commercial Real Estate Broker",
      //   "LIEN_VIDEO_F": "",
      //   "LIEN_VIDEO_A": "",
      //   "PRESENTATION_F": "",
      //   "PRESENTATION_A": "\r",
      //   "description":"Jan-Otto Bauer had the privilege of growing up in the picturesque town of Mont-Tremblant. Surrounded by the breathtaking beauty of the Laurentian Mountains and serene lakes, Mont-Tremblant has always held a special place in his heart. From a young age, Jan-Otto developed a deep appreciation for the real estate industry, and it was during his formative years in Mont-Tremblant that his passion for secondary residences began to flourish. The allure of these comfortable retreats nestled amidst nature became the focal point of his professional career. As an experienced Real Estate Broker, Jan-Otto has dedicated himself to meeting the unique needs of those seeking their own tranquil oasis. With an extensive knowledge of the local market, he understands the distinctive features and charm that make each property truly special. Being trilingual (French, English, German), Jan-Otto strives to provide his clients with a personalized and seamless experience. Throughout his career, he has developed a strong network of clients and industry professionals who rely on his expertise and dedication. Jan-Otto stays informed about market trends, property values, and the latest advancements in the real estate industry, allowing him to offer insightful advice and tailored strategies to his clients, ensuring the success of their real estate goals. As Jan-Otto always states, “A residence in the Laurentians is more than just a real estate investment, it’s a sanctuary where memories are created and cherished for a lifetime.”",
      //   "company_info": {
      //     "CODE": "EVT001",
      //     "FIRME_CODE": "EVT",
      //     "NOM_LEGAL": "SIEGE SOCIAL",
      //     "NO_CIVIQUE": "1000",
      //     "NOM_RUE": "Chemin des Voyageurs",
      //     "BUREAU": "",
      //     "MUNICIPALITE": "Mont-Tremblant",
      //     "PROVINCE": "QC",
      //     "CODE_POSTAL": "J8E1T1",
      //     "TELEPHONE_1": "8196815000",
      //     "POSTE_1": "",
      //     "TELEPHONE_2": "",
      //     "POSTE_2": "",
      //     "TELEPHONE_FAX": "8196815700",
      //     "COURRIEL": "tremblant@evrealestate.com",
      //     "SITE_WEB": "",
      //     "DIRECTEUR_CODE": "91377",
      //     "URL_LOGO_BUREAU": "https://mediaserver.centris.ca/media.ashx?id=ADDE1A2DDDDDFCFDDDB0842DDA&t=b&w=200&h=150"
      //   }
      // },
      {
        "CODE": "58481",
        "BUR_CODE": "EVT001",
        "NO_CERTIFICAT": "H1290",
        "TYPE_CERTIFICAT": "AF",
        "NOM": "Ouimet",
        "PRENOM": "Pierre",
        "TITRE_PROFESSIONNEL": "Real Estate Broker",
        "CHAMP_INUTILISE_1": "",
        "TELEPHONE_1": "1 (819) 429-0730",
        "TELEPHONE_2": "8196815000",
        "TELEPHONE_FAX": "8196815700",
        "COURRIEL": "pouimet@mtre.ca",
        "LINKEDIN":"https://www.linkedin.com/in/pierre-ouimet-84243536/overlay/about-this-profile/",
        "FB":"https://www.facebook.com/pierreetcynthialesimmeublesmonttremblant",
        "SITE_WEB": "http://www.pierreetcynthia.com",
        "CHAMP_INUTILISE_2": "",
        "CODE_LANGUE": "F",
        "PHOTO_URL": Ouimet,
        "DATE_MODIFICATION": "2024/08/14 11:29:42",
        "NOM_SOCIETE": "",
        "TYPE_SOCIETE_DESC_F": "",
        "TYPE_SOCIETE_DESC_A": "",
        "LIEN_VIDEO_F": "",
        "LIEN_VIDEO_A": "",
        "PRESENTATION_F": "",
        "PRESENTATION_A": "\r",
        "description":"Pierre is a native of Mont-Tremblant and started as a broker in 1988. After 31 years in the business, he still has the passion and commitment to fulfill the needs of his clients. He is very knowledgeable about the numerous lakes and communities surrounding Mont-Tremblant. His expertise in both residential and commercial real estate has built him a solid distinction with his clients. 12 years ago, he decided to partner with Cynthia Griffin and together they built a reputable international and local client base. As a bilingual team Pierre and Cynthia promise to BEND OVER TO PLEASE!",
        "company_info": {
          "CODE": "EVT001",
          "FIRME_CODE": "EVT",
          "NOM_LEGAL": "SIEGE SOCIAL",
          "NO_CIVIQUE": "1000",
          "NOM_RUE": "Chemin des Voyageurs",
          "BUREAU": "",
          "MUNICIPALITE": "Mont-Tremblant",
          "PROVINCE": "QC",
          "CODE_POSTAL": "J8E1T1",
          "TELEPHONE_1": "8196815000",
          "POSTE_1": "",
          "TELEPHONE_2": "",
          "POSTE_2": "",
          "TELEPHONE_FAX": "8196815700",
          "COURRIEL": "tremblant@evrealestate.com",
          "SITE_WEB": "",
          "DIRECTEUR_CODE": "91377",
          "URL_LOGO_BUREAU": "https://mediaserver.centris.ca/media.ashx?id=ADDE1A2DDDDDFCFDDDB0842DDA&t=b&w=200&h=150"
        }
      },
      {
        "CODE": "87724",
        "BUR_CODE": "500407",
        "NO_CERTIFICAT": "C9977",
        "TYPE_CERTIFICAT": "AF",
        "NOM": "Griffin",
        "PRENOM": "Cynthia",
        "TITRE_PROFESSIONNEL": "Real Estate Broker",
        "CHAMP_INUTILISE_1": "",
        "TELEPHONE_1": "1 (819) 421-4663",
        "TELEPHONE_2": "",
        "TELEPHONE_FAX": "",
        "COURRIEL": "cgriffin@mtre.ca",
        "LINKEDIN":"https://www.linkedin.com/in/cynthiagriffin1/overlay/about-this-profile/",
        "FB":"https://www.facebook.com/pierreetcynthialesimmeublesmonttremblant",
        "SITE_WEB": "",
        "CHAMP_INUTILISE_2": "",
        "CODE_LANGUE": "A",
        "PHOTO_URL": Griffin,
        "DATE_MODIFICATION": "2024/09/30 17:26:37",
        "NOM_SOCIETE": "",
        "TYPE_SOCIETE_DESC_F": "",
        "TYPE_SOCIETE_DESC_A": "",
        "LIEN_VIDEO_F": "",
        "LIEN_VIDEO_A": "",
        "PRESENTATION_F": "",
        "PRESENTATION_A": "\r",
        "description":"Cynthia was raised in Montreal, leading a sporty lifestyle and was very fortunate to be introduced to Tremblant as a youngster as her family came up north to ski every weekend. She quickly fell in love with Mont-Tremblant and decided to move there after college. She worked in both the ski and golf industry while raising two children. In 2006, Cynthia’s career in real estate began with her knowledge and appreciation of the region, it became second nature. Creativity, socializing, organizing and helping others are her specialties. So when you give her the ideal criteria for your dream home she is able to make it happen. She partnered with Pierre Ouimet in 2007 and they became a very successful, bilingual, Real Estate Team. As their logo reads - WE BEND OVER TO PLEASE!",
        "company_info": {
          "CODE": "500407",
          "FIRME_CODE": "EGV",
          "NOM_LEGAL": "Pointe-Claire",
          "NO_CIVIQUE": "288",
          "NOM_RUE": "ch. du Bord-du-Lac (Lakeshore)",
          "BUREAU": "",
          "MUNICIPALITE": "Pointe-Claire",
          "PROVINCE": "QC",
          "CODE_POSTAL": "H9S4K9",
          "TELEPHONE_1": "5145077888",
          "POSTE_1": "",
          "TELEPHONE_2": "",
          "POSTE_2": "",
          "TELEPHONE_FAX": "",
          "COURRIEL": "montreal@evcanada.com",
          "SITE_WEB": "",
          "DIRECTEUR_CODE": "84903",
          "URL_LOGO_BUREAU": "https://mediaserver.centris.ca/media.ashx?id=ADDE1A2DDDDD65CDDDB0842DD1&t=b&w=200&h=150"
        }
      },
      {
        "CODE": "113426",
        "BUR_CODE": "HMA006",
        "NO_CERTIFICAT": "H6230",
        "TYPE_CERTIFICAT": "CRES",
        "NOM": "Lambert",
        "PRENOM": "Véronique",
        "TITRE_PROFESSIONNEL": "Residential Real Estate Broker",
        "CHAMP_INUTILISE_1": "",
        "TELEPHONE_1": "1 (514) 993-8778",
        "TELEPHONE_2": "",
        "TELEPHONE_FAX": "",
        "COURRIEL": "vlambert@mtre.ca",
        "INSTA":"https://www.instagram.com/veronique_mont_tremblant/",
        "LINKEDIN":"https://www.linkedin.com/in/veronique-lambert-870b2a145/",
        "FB":"https://www.facebook.com/veronique.lambert.372",
        "SITE_WEB": "www.royallepagehumania.ca",
        "CHAMP_INUTILISE_2": "",
        "CODE_LANGUE": "F",
        "PHOTO_URL": Lambert,
        "DATE_MODIFICATION": "2023/04/06 14:02:16",
        "NOM_SOCIETE": "",
        "TYPE_SOCIETE_DESC_F": "",
        "TYPE_SOCIETE_DESC_A": "",
        "LIEN_VIDEO_F": "",
        "LIEN_VIDEO_A": "",
        "PRESENTATION_F": "",
        "PRESENTATION_A": "\r",
        "description":"Véronique has been living in Mont-Tremblant for several years, as well as owning several properties located at the mountain. Her involvement in the community has enabled her to develop an excellent network of contacts involved in the field of real estate and with several stakeholders and suppliers necessary for the realization of successful real estate transactions. Architectural technician and author of several self-construction projects, Véronique recently decided to use her skills and experience as a real estate broker. An accomplished and well-known athlete in the region, this mother of two young adults stands out for her great listening skills and dedication to her clients. Her dynamism, drive, enthusiasm and great determination will allow you to realize your real estate dream",
        "company_info": {
          "CODE": "HMA006",
          "FIRME_CODE": "HMA",
          "NOM_LEGAL": "Mont-Tremblant",
          "NO_CIVIQUE": "3-614",
          "NOM_RUE": "rue de Saint-Jovite",
          "BUREAU": "",
          "MUNICIPALITE": "Mont-Tremblant",
          "PROVINCE": "QC",
          "CODE_POSTAL": "J8E0T4",
          "TELEPHONE_1": "8194296464",
          "POSTE_1": "",
          "TELEPHONE_2": "",
          "POSTE_2": "",
          "TELEPHONE_FAX": "8194295425",
          "COURRIEL": "Humania@royallepage.ca",
          "SITE_WEB": "",
          "DIRECTEUR_CODE": "102299",
          "URL_LOGO_BUREAU": "https://mediaserver.centris.ca/media.ashx?id=ADDE1A2DDDDD763DDDB0842DDE&t=b&w=200&h=150"
        }
      }
]
