import React from 'react';
import './App.css';
import { createBrowserRouter, RouterProvider, Route, useLocation } from 'react-router-dom';
import HomeComponent from './pages/home';
import Footer from './components/footer';
import Listings from './pages/listings';
import { LanguageProvider } from './context/languageContext';
import Collections from './pages/collections';
import IndividualCollections from './pages/individualCollections';
import IndividualProperty from './pages/individualProperty';
import { FilterProvider } from "./context/filterContext";
import Rentals from './pages/rentalsPage';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BrokersList from './pages/brokerslist';
import BrokerSingle from './pages/individualbroker';
import Individualbroker from './pages/broker-single';
import ContactPage from './pages/contact';
import MontTremblant from './pages/communities/mont-tremblant';
import LacSuperior from './pages/communities/lac-superiur';
import MontBlac from './pages/communities/mont-blac';
import SainteAgatheDesMonts from './pages/communities/sainte-agathe-des-monts';
import Laurenties from './pages/communities/laurentians';
import LabelleSurroundings from './pages/communities/labelle-suurondings';
import ScrollToTop from './components/scroll-top';
import LeagalNotice from './pages/legal-noice';
import About from './pages/about';
import SinglePropertiesMap from './components/singlePropertiesMap';
import NewMap from './components/newMap';
import Blog from './pages/blog';
import TestmonialPage from './components/testmonialPage';
import GlobalLuxurySearch from './components/globalLuxurySearch';
import { HelmetProvider } from "react-helmet-async";
import Domaine from './pages/blog/domaine';
import VillagePietonnier from './pages/blog/villagePietonnier';
import BlogListing from './pages/blog/blogListings';



const router = createBrowserRouter(
  [
    { path: "/", element: <><ScrollToTop /><HomeComponent /></> },
    { path: "/:lang", element: <><ScrollToTop /><HomeComponent /></> },
    { path: "/:lang/listings", element: <><ScrollToTop /><Listings /></> },
    { path: "/:lang/collections", element: <><ScrollToTop /><Collections /></> },
    { path: "/:lang/rentals", element: <><ScrollToTop /><Rentals /></> },
    { path: "/:lang/collections/:title", element: <><ScrollToTop /><IndividualCollections price={''} /></> },
    { path: "/:lang/listings.php", element: <><ScrollToTop /><IndividualProperty /></> },
    { path: "/:lang/brokers", element: <><ScrollToTop /><BrokersList /></> },
    { path: "/:lang/broker/:id", element: <><ScrollToTop /><BrokerSingle /></> },
    { path: "/:lang/agent/:id", element: <><ScrollToTop /><Individualbroker /></> },
    { path: "/:lang/contact", element: <><ScrollToTop /><ContactPage /></> },
    { path: "/:lang/communities/mont-tremblant", element: <><ScrollToTop /><MontTremblant /></> },
    { path: "/:lang/communities/lac-superieur", element: <><ScrollToTop /><LacSuperior /></> },
    { path: "/:lang/communities/mont-blac", element: <><ScrollToTop /><MontBlac /></> },
    { path: "/:lang/communities/sainte-agathe-des-monts", element: <><ScrollToTop /><SainteAgatheDesMonts /></> },
    { path: "/:lang/communities/laurentians", element: <><ScrollToTop /><Laurenties /></> },
    { path: "/:lang/communities/labelle-surroundings", element: <><ScrollToTop /><LabelleSurroundings /></> },
    { path: "/:lang/legal-notice", element: <><ScrollToTop /><LeagalNotice /></> },
    { path: "/:lang/about", element: <><ScrollToTop/><About/></> },
    { path: "/:lang/maps", element: <><ScrollToTop/><NewMap/></> },
    { path: "/:lang/blog", element: <><ScrollToTop/><Blog/></> },
    { path: "/:lang/global-luxury-search", element: <><ScrollToTop/><GlobalLuxurySearch/></> },
    { path: "/:lang/village", element: <><ScrollToTop/><VillagePietonnier/></> },
    { path: "/:lang/domaine", element: <><ScrollToTop/><Domaine/></> },
    { path: "/:lang/bloglisting/:id", element: <><ScrollToTop/><BlogListing/></> },



    // { path: "/testmonial", element: <><ScrollToTop/><TestmonialPage/></> }






  ],
  {
    future: {
      v7_relativeSplatPath: true,
    },
  }
);

function App() {
  return (
    <div className="App">
      <HelmetProvider >
      <LanguageProvider>
        <FilterProvider>
      <RouterProvider router={router} />
      <Footer  />
      </FilterProvider>
      </LanguageProvider>
      </HelmetProvider>
    </div>
  );
}

export default App;
