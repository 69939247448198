import React, { useEffect } from "react";
import Header from "../../components/header";
import img1 from '../../assets/images/04Ste-Agathe.webp';
import img3 from '../../assets/images/Ste-gathe-2.webp';
import img4 from '../../assets/images/steAgathe-3.webp';
import img5 from '../../assets/images/steAgathe-4.webp'
import img6 from '../../assets/images/steAgathe-5.webp';
import img7 from '../../assets/images/Sainte-Agathe-des-Monts,_Quebec_-_église_-_5.webp';
import { useTranslation } from "react-i18next";
import { PiCurrencyCircleDollarThin } from "react-icons/pi";
import { IoPersonOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import { PiPersonSimpleWalkLight } from "react-icons/pi";

const MontBlac = () => {

    const {t} = useTranslation();
    useEffect(()=>{
      document.title = t('title.agathe')
  },[t])


  const replaceString = (str: string) => {
    return str.replace(/41\.2/g, "47")
              .replace(/\$90567/g, "$74,600")
              .replace(/7,019/g, "10,992")
              .replace(/41,2/g, "47")
              .replace(/90 567 \$/g, "74 600 $")
              .replace(/7 019/g, "10 992")
              .replace(/High/g, "Low")
              .replace(/\$90,567/g, "$72,600")
              .replace(/Haut/g, "Bas")
  
  }

    const boldifyText = (text:string, boldWords:string[]) => {
        const regex = new RegExp(`(${boldWords.join("|")})`, "gi"); // Match any word in the array
        const parts = text.split(regex);
    
        return parts.map((part, index) =>
          boldWords.some((word) => word.toLowerCase() === part.toLowerCase()) ? (
            <span className="font-semibold" key={index}>{part}</span>
          ) : (
            <span key={index}>{part}</span>
          )
        );
      };

     

    return (
        <><Header /><div className="md:px-20 px-4 md:pt-36 pt-24">
            {/* Heading */}
            <div className="font-light text-start">
                <h1 className="text-2xl text-yellow-500 mt-3">{t("communities.sainte-agathe.explore")}</h1>
                <h1 className="text-4xl md:text-6xl ">{t("communities.sainte-agathe.header")}</h1>
                <p className="text-gray-600 mt-2"><span className="font-semibold">{t("communities.sainte-agathe.01")}</span>{t("communities.sainte-agathe.explore1")}</p>
            </div>

            {/* content-1 */}
            <div className="p-2 flex gap-2 lg:mt-5 flex-col lg:flex-row">
    <div className='w-full lg:w-1/2 lg:p-5 md:h-[400px]'>
        <img src={img1} alt='mont-blac' className='rounded-lg h-full w-full object-cover'></img>
    </div>
    <div className='w-full lg:w-1/2 text-start lg:p-5 flex flex-col gap-4'>
        <h1 className='text-yellow-500 text-2xl font-light mt-2'>{t("communities.sainte-agathe.geography.00")}</h1>
        <p className='text-gray-500'>{boldifyText(t("communities.sainte-agathe.geography.01"),["Sainte-Agathe-des-Monts","Lac des Sables"])}</p>
        <p className='text-gray-500'>{boldifyText(t("communities.sainte-agathe.geography.02"),["Laurentian Mountains","les Laurentides"])}</p>
    </div>
</div>

{/* content-2 */}
<div className="p-2 flex gap-2 lg:mt-5 items-stretch flex-col lg:flex-row">
    <div className='w-full lg:w-1/2 text-start lg:p-5 flex flex-col gap-4'>
        <h1 className='text-yellow-500 text-2xl font-light mt-2'>{t("communities.sainte-agathe.outdoor.00")}</h1>
        <p className='text-gray-500 mt-2'>{t("communities.sainte-agathe.outdoor.01")}</p>
        <p className='text-gray-500 mt-2'>
            {boldifyText(t("communities.sainte-agathe.outdoor.02"),["Winter","Mont-Tremblant","Mont Blanc","Hiver"])}
        </p>
        <p className='text-gray-500 mt-2'>
            {boldifyText(t("communities.sainte-agathe.outdoor.03"),["Summer","Lac des Sables","Tessier Beach","Été"])}
        </p>
    </div>
    <div className='w-full lg:w-1/2 text-start lg:h-[400px]'>
        <img src={img3} alt='mont-blac' className='rounded-lg h-full w-full object-cover'></img>
    </div>
</div>

{/* content-3 */}
<div className="p-2 flex gap-2 lg:mt-5 items-stretch flex-col-reverse lg:flex-row">
    <div className='w-full lg:w-1/2 lg:p-5 lg:h-[400px]'>
        <img src={img4} alt='mont-tremblant' className='rounded-lg h-full w-full object-cover'></img>
    </div>
    <div className='w-full lg:w-1/2 text-start lg:p-5 flex flex-col gap-4'>
        <h1 className='text-yellow-500 text-2xl font-light mt-2'>{t("communities.sainte-agathe.real-estate.00")}</h1>
        <p className='text-gray-500'>{boldifyText(t("communities.sainte-agathe.real-estate.01"),["Sainte-Agathe-des-Monts"])} {boldifyText(t("communities.sainte-agathe.real-estate.02"),["Lakeside homes","Les maisons et chalets","Lac des Sables","vacation homes","maisons de vacances"])}</p>
        <p className='text-gray-500'>{t("communities.sainte-agathe.real-estate.03")}</p>
    </div>
</div>

{/* content-4 */}
<div className="p-2 flex gap-2 lg:mt-5 items-stretch flex-col lg:flex-row">
    <div className='w-full lg:w-1/2 text-start lg:p-5 flex flex-col gap-4'>
        <h1 className='text-yellow-500 text-2xl font-light mt-2'>{t("communities.sainte-agathe.community.00")}</h1>
        <p className='text-gray-500 mt-2'>
            {boldifyText(t("communities.sainte-agathe.community.01"),["Sainte-Agathe-des-Monts","Hôpital Laurentien"])}
        </p>
        <p className='text-gray-500 mt-2'>
            {boldifyText(t("communities.sainte-agathe.community.02"),["Educational institutions","Les Week-ends Gourmands","Les établissements d'enseignement"])}
        </p>
    </div>
    <div className='w-full lg:w-1/2 lg:p-5 lg:h-[400px] text-start'>
        <img src={img5} alt='mont-tremblant' className='rounded-lg h-full w-full object-cover'></img>
    </div>
</div>

{/* content-5 */}
<div className="p-2 flex gap-2 lg:mt-5 items-stretch flex-col-reverse lg:flex-row">
    <div className="w-full lg:w-1/2 lg:p-5 ">
        <img src={img6} alt="mont-tremblant-natinal park" className="rounded-lg h-[250px] md:h-[350px] lg:h-full w-full object-cover" />
    </div>
    <div className="w-full lg:w-1/2 text-start lg:p-5 flex flex-col gap-4">
        <h1 className="text-yellow-500 text-2xl font-light mt-2">{t("communities.sainte-agathe.attractions.00")}</h1>
        <p className="text-gray-500 mt-2">
            {boldifyText(t("communities.sainte-agathe.attractions.01"),["Lac des Sables"])}
        </p>
        <p className="text-gray-500 mt-2">{boldifyText(t("communities.sainte-agathe.attractions.02"),["Theatre Le Patriote","Théâtre Le Patriote"])}</p>
        <p className="text-gray-500 mt-2">{boldifyText(t("communities.sainte-agathe.attractions.03"),["Ski Resorts","Mont Blanc","Mont-Tremblant","Stations de ski :"])}</p>
        <p className="text-gray-500 mt-2">{boldifyText(t("communities.sainte-agathe.attractions.04"),["Laurentian Mountains","Mont-Tremblant National Park","Montagnes Laurentides","Le parc national du Mont-Tremblant "])}</p>
    </div>
</div>


            {/* contenet - 6  */}
            <div className="p-2 flex gap-2 lg:mt-5 items-stretch flex-col lg:flex-row">
    <div className="w-full lg:w-1/2 text-start lg:p-5 flex flex-col gap-4">
        <h1 className="text-yellow-500 text-2xl font-light mt-2">{t("communities.sainte-agathe.population.00")}</h1>
        <p className="text-gray-500 mt-2">{t("communities.sainte-agathe.population.01")}</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 pt-7 pb-5 w-full">
      {/* First Icon Card */}
      <div className="flex items-center gap-2">
        <div className="w-10 h-10 rounded-3xl bg-gray-400 flex items-center justify-center">
          <CiLocationOn color="white" size={24} />
        </div>
        <div>
          <p className="text-sm text-gray-700">10,233</p>
          <p className="text-sm text-gray-400">{t("communities.tremblant.population.04")}</p>
        </div>
      </div>

      {/* Second Icon Card */}
      <div className="flex items-center gap-2">
        <div className="w-10 h-10 rounded-full bg-gray-400 flex items-center justify-center">
          <IoPersonOutline size={24} color="white" />
        </div>
        <div>
          <p className="text-sm text-gray-700">{replaceString(t("communities.tremblant.population.05"))}</p>
          <p className="text-sm text-gray-400">{t("communities.tremblant.population.06")}</p>
        </div>
      </div>

      {/* Third Icon Card */}
      <div className="flex items-center gap-2">
        <div className="w-10 h-10 rounded-full bg-gray-400 flex items-center justify-center">
          <PiPersonSimpleWalkLight size={24} color="white" />
        </div>
        <div>
          <p className="text-sm text-gray-700">{replaceString(t("communities.tremblant.population.07"))}</p>
          <p className="text-sm text-gray-400">{t("communities.tremblant.population.08")}</p>
        </div>
      </div>

      {/* Fourth Icon Card */}
      <div className="flex items-center gap-2">
        <div className="w-10 h-10 rounded-full bg-gray-400 flex items-center justify-center">
          <PiCurrencyCircleDollarThin size={30} color="white" />
        </div>
        <div>
          <p className="text-sm text-gray-700">$58,900</p>
          <p className="text-sm text-gray-400">{t("communities.tremblant.population.09")}</p>
        </div>
      </div>
    </div>
    </div>
    <div className="w-full lg:w-1/2 lg:p-5 lg:h-[400px]">
        <img src={img7} alt="mont-tremblant" className="rounded-lg h-full w-full object-cover" />
    </div>
</div>


<div className="p-2 flex flex-col lg:flex-row gap-2 items-stretch">

<div className="w-full lg:w-1/2 text-start lg:ps-5 lg:py-5 flex flex-col">
    <p className="text-xl font-semibold mt-2 pb-2 text-gray-800">{t("communities.lac.school")}</p>
    <p className="text-gray-500 ms-2">-École Fleurs des Neiges</p>
    <p className="text-gray-500 ms-2">-École Lionel-Groulx</p>
    <p className="text-gray-500 ms-2">-École Ste-Agathe</p>
</div>

<div className="w-full lg:w-1/2 lg:ps-5 lg:py-5 text-start">
    <p className="text-xl font-semibold pb-2 mt-2 text-gray-800">{t("communities.sainte-agathe.school-sec")}</p>
    <p className="text-gray-500 ms-2">-École Polyvalente des Monts</p>
    <p className="text-gray-500 lg:pb-10 ms-2">-École Ste-Agathe</p>
</div>

</div>


            <div className='lg:ps-7 ps-2 pb-7 mt-2 text-start text-gray-400'>
                <h1 className='text-lg font-semibold text-gray-800'>{t("communities.English-Schools")}</h1>
                <p className='text-gray-500 mt-2'>{t("communities.sainte-agathe.school-eng.00")}</p>

                <p className='text-gray-500 mt-2'>{t("communities.sainte-agathe.school-eng.01")}</p>
                {/* <p className='text-gray-500 mt-2'>{t("communities.sainte-agathe.school-eng.02")}</p> */}
            </div>

        </div></>
    )
}

export default MontBlac