import React, { useState, useEffect, useRef } from 'react';
import logo2 from '../assets/images/IMT_logo_coul3.webp';
import logo from '../assets/images/IMT_logo_coul.webp'
import LanguageSelector from './languageSelector';
import { useNavigate, matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IoIosArrowDown } from "react-icons/io";
import LoginDialog from './LoginDialog';
import Cookies from 'js-cookie';
import { CgProfile } from "react-icons/cg";
import { FaRegHeart } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import LogoutConfirmationPopup from './logoutConfirmationPopup';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  // const [currentPath, setCurrentPath] = useState(location.pathname);
  let currentPath = location.pathname;



  // useEffect(() => {
  //   setCurrentPath(location.pathname);
  //   // console.log("useEffect is triggered with path:", currentPath);
  // },[location])

  // useEffect(()=>{
  //   console.log(currentPath);
  // },[currentPath])

  


 

  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);

  const history = useRef<string[]>([]);
  const { i18n } = useTranslation();


  let language = i18n.language;
  // useEffect(() => {
  //   const language = i18n.language;
  // }, [i18n.language]);







  const openLoginDialog = () => {
    setIsLoginDialogOpen(true);
  };

  const closeLoginDialog = () => {
    setIsLoginDialogOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);



  


  const [originRoute, setOriginRoute] = useState<string | null>(null);

  useEffect(() => {
    if (location.pathname.startsWith(`/${language}/listings`)) {
      localStorage.setItem("originRoute", `/${language}/listings`);
      setOriginRoute(`${language}/listings`);
    } else if (location.pathname.startsWith(`/${language}/rentals`)) {
      localStorage.setItem("originRoute", `/${language}/rentals`);
      setOriginRoute(`/${language}/rentals`);
    } else if (location.pathname.startsWith(`/${language}/property`)) {
      const savedRoute = localStorage.getItem("originRoute");
      setOriginRoute(savedRoute);
    }
  }, [location.pathname]);

  const isActive = (path: string) => originRoute === path;

  const propertyPaths = [
    `/${language}/collections`,
    `/${language}/listings`,
    `/${language}/rentals`,
    `/${language}/collections/:title`,
    `/${language}/brokers`,
    `/en/contact`,
    `/fr/contact`,

    `/en/communities/mont-tremblant`,
    `/fr/communities/mont-tremblant`,

    `/en/communities/lac-superieur`,
    `/fr/communities/lac-superieur`,

    `/en/communities/mont-blac`,
    `/fr/communities/mont-blac`,

    `/en/communities/sainte-agathe-des-monts`,
    `/fr/communities/sainte-agathe-des-monts`,

    `/en/communities/laurentians`,
    `/fr/communities/laurentians`,

    `/en/communities/labelle-surroundings`,
    `/fr/communities/labelle-surroundings`,

    `/fr/global-luxury-search`,
    `/en/global-luxury-search`,

    `/en/legal-notice`,
    `/fr/legal-notice`,


    `/fr/about`,
    `/en/about`,

    `/fr/maps`,
    `/en/maps`,

    `/fr/blog`,
    `/en/blog`,

    `/fr/village`,
    `/en/village`,

    `/fr/domaine`,
    `/en/domaine`,
   

  ];


const isStaticPath = propertyPaths.includes(currentPath);


  

  const isDynamicPath = matchPath(`/:lang/listings.php`, currentPath) ||
    matchPath(`/:lang/agent/:id`, currentPath) ||
    matchPath(`/:lang/listings`, currentPath) ||
    matchPath(`/:lang/rentals`, currentPath) ||
    matchPath(`/:lang/brokers`, currentPath) ||
    matchPath(`/:lang/contacts`, currentPath) ||
    matchPath(`/:lang/bloglisting/:id`, currentPath)||
    matchPath(`/:lang/collections`, currentPath)||
    matchPath(`/:lang/contacts`,currentPath) ||
    matchPath(`/:lang/communities/mont-tremblant`, currentPath) ||
    matchPath(`/:lang/communities/lac-superieur`, currentPath) ||
    matchPath(`/:lang/communities/mont-blac`, currentPath) ||
    matchPath(`/:lang/communities/sainte-agathe-des-monts`, currentPath) ||
    matchPath(`/:lang/communities/laurentians`, currentPath) ||
    matchPath(`/:lang/communities/labelle-surroundings`, currentPath) ||
    matchPath(`/:lang/global-luxury-search`, currentPath)
   
    ;





  const isPropertyPage = isStaticPath || isDynamicPath;
  const [isContactMenuVisible, setIsContactMenuVisible] = useState(false);
  const user = Cookies.get('userDetails') ? JSON.parse(Cookies.get('userDetails') as string) : null;

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const handleNav = (path: string) => {
    navigate(path);
    setIsSidebarOpen(false);
  };
  const logOut = () => {
    Cookies.remove('userDetails');
    setShowProfileMenu(false)
    setIsSidebarOpen(false);
    setShowLogoutConfirmation(false);

  }

  const logOutConfirm = () => {
    setShowLogoutConfirmation(!showLogoutConfirmation);
    setShowProfileMenu(false)
    setIsSidebarOpen(false);
  }

  useEffect(() => {
    history.current.push(location.pathname);
  }, [location.pathname]);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  // const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth


  const calculateScrollbarWidth = () => {
    if (window.innerWidth > document.documentElement.clientWidth) {
      const scrollDiv = document.createElement('div');
      scrollDiv.style.overflow = 'scroll';
      scrollDiv.style.width = '100px';
      scrollDiv.style.height = '100px';
      scrollDiv.style.visibility = 'hidden';
      document.body.appendChild(scrollDiv);
      const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      document.body.removeChild(scrollDiv);
      return scrollbarWidth;
    }
    return 0; // No scrollbar
  };

  useEffect(() => {
    const updateScrollbarWidth = () => {
      setScrollbarWidth(calculateScrollbarWidth());
    };

    // Initial calculation
    updateScrollbarWidth();

    // Update on window resize
    window.addEventListener('resize', updateScrollbarWidth);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('resize', updateScrollbarWidth);
    };
  }, []);

  useEffect(() => {
    // Add or remove the class from the body element
    if (isSidebarOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    // Cleanup on unmount
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [isSidebarOpen]);



  return (
    <header style={{
      width: '-webkit-fill-available',
      marginRight: (isLoginDialogOpen && isPropertyPage) || (showLogoutConfirmation && isPropertyPage) ? `${scrollbarWidth}px` : undefined,
    }}
      className={`flex justify-center items-center px-4 py-2 z-[460] w-full 
      ${(isLoginDialogOpen && isPropertyPage)
          ? 'fixed bg-pantone288-600 text-yellow-500'
          : isPropertyPage
            ? 'fixed bg-pantone288-600 text-yellow-500'
            : 'absolute bg-transparent text-white'
        }`}
    >









      <div className=' flex gap-7 items-center w-full justify-between'>

        <div className="flex flex-col items-center" onClick={() => navigate(`/${language}/`)}>
          <img src={logo} alt='logo' width={100} className="sm:w-24 md:w-28 lg:w-[9rem] z-20  cursor-pointer " />
        </div>

        {/* Hamburger Icon for Mobile */}
        <div className="lg:hidden flex items-center" onClick={toggleSidebar}>
          <FaBars className="text-2xl text-gray-200 cursor-pointer" />
        </div>

        {/* Sidebar for Mobile */}
        <div className={`lg:hidden fixed inset-0 bg-black bg-opacity-50 z-30 transition-all ${isSidebarOpen ? 'block' : 'hidden'}`} onClick={toggleSidebar}></div>
        {/* style={{background: 'linear-gradient(to right,#2c87bd, #173167)'}} */}
        <div className={`lg:hidden fixed left-0 top-0  bg-white text-black w-3/4 h-full z-40 transition-all transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}>
          <div className="flex flex-col h-full">
            {/* Header section with logo and close icon */}
            <div className="flex justify-between items-start  p-4">
              <div className="cursor-pointer  flex flex-col items-center  " onClick={() => navigate('/')}>
                {/* <img src={top} alt='logo' width={120} className="sm:w-24 md:w-28 lg:w-[134px]   " />
                <img src={leftLogo} alt='logo' width={130} className="sm:w-24 md:w-28 lg:w-42 rounded-sm " /> */}
                <img src={logo} alt='logo' width={110} className="sm:w-24 md:w-28 lg:w-32  cursor-pointer " />

              </div>
              <FaTimes className="text-3xl cursor-pointer" onClick={toggleSidebar} />
            </div>

            {/* Navigation links */}
            <nav className="flex flex-col space-y-4 p-4">
              <a className={`hover:text-gray-300 cursor-pointer ${currentPath === ':lang/collections' ? 'text-gray-500' : ''}`}
                onClick={() => handleNav(`/${language}/collections`)}>{t('navigation.collections')}</a>
              <a className={`hover:text-gray-300 uppercase cursor-pointer ${currentPath === '/listings' ? 'text-gray-500' : ''}` || `${currentPath === '/listings/:id' ? 'text-gray-300' : ''} `}
                onClick={() => handleNav(`/${language}/listings`)}>{t('navigation.properties')}</a>
              <a onClick={() => handleNav(`/${language}/rentals`)} className={`hover:text-gray-500 cursor-pointer ${currentPath === '/rentals' ? 'text-gray-500' : ''}`}>{t('navigation.rentals')}</a>
              <a onClick={() => handleNav(`/${language}/brokers`)} className={`hover:text-gray-500 cursor-pointer ${currentPath === '/brokers' || currentPath.startsWith('/agent/') ? 'text-gray-500' : ''}`}>{t('navigation.brokers')}</a>
              <a onClick={() => handleNav(`/${language}/about`)} className={`hover:text-gray-500 cursor-pointer ${currentPath === '/about' ? 'text-gray-500' : ''}`}>{t('navigation.about')}</a>
              <a onClick={() => handleNav(`/${language}/contact`)} className={`hover:text-gray-500 cursor-pointer ${currentPath === '/contact' ? 'text-gray-500' : ''}`}>{t('navigation.contact')}</a>
              <a onClick={() => handleNav(`/${language}/blog`)} className={`hover:text-gray-500 cursor-pointer ${currentPath === '/blog' ? 'text-gray-500' : ''}`}>{t('title.blog')}</a>
              {/* <div className=' block'>
                {user ? (
                  <>
                    <div className='flex items-center justify-center gap-3 w-full  '>
                    <img src={user.photoUrl} alt="user" className="w-10 h-10 rounded-full cursor-pointer"
                      onClick={() => setShowProfileMenu(!showProfileMenu)} />
                    </div>
                    <div className=''>

                      <div className='  rounded-lg z-30'>
                        <a className='block text-black hover:text-gray-500 p-2 '>
                          <CgProfile className='inline-block mr-2' />
                          {t('profile.00')}</a>
                        <a className='block text-black hover:text-gray-500 p-2 '>
                          <FaRegHeart className='inline-block mr-2' />
                          {t('profile.01')}</a>
                        <a className='block text-Red-600 hover:text-gray-500 p-2 cursor-pointer ' onClick={() => logOutConfirm()}>
                          <MdLogout className='inline-block mr-2' />
                          {t('profile.02')}</a>
                      </div>
                    </div></>

                ) : (
                  <div className='flex w-full justify-center'>

                    <button className=" absolute bottom-24   px-3 py-1 bg-pantone288-500 border-yellow-500 border rounded-full hover:bg-white hover:text-yellow-500 text-white   transition duration-300"
                      onClick={() => { openLoginDialog(); setIsSidebarOpen(!isSidebarOpen) }}>
                      {t('signup.05')}
                    </button>
                  </div>

                )
                }




              </div> */}

            </nav>

            {/* Language selector positioned at the bottom */}
            <div className="mt-auto p-4 mb-10">
              <LanguageSelector />
            </div>
          </div>
        </div>



        {/* Desktop Navigation */}
        <nav className="hidden lg:flex space-x-6 w-full justify-center -ml-44 ">
          <a
            className={`hover:text-gray-300 cursor-pointer ${currentPath === `/${language}/collections` ? 'text-white' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/${language}/collections`);
            }}
          >
            {t('navigation.collections')}
          </a>
          <a
            className={`hover:text-gray-300 uppercase cursor-pointer ${currentPath===(`/${language}/listings`) ? 'text-white' : ''
              }`}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/${language}/listings`);
            }}
          >
            {t('navigation.properties')}
          </a>
          <a
            className={`hover:text-gray-300 cursor-pointer ${isActive(`/${language}/rentals`) ? 'text-white' : ''
              }`}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/${language}/rentals`);
            }}
          >
            {t('navigation.rentals')}
          </a>

          <a
            onClick={(e) => {
              e.preventDefault();
              navigate(`/${language}/brokers`);
            }}
            className={`hover:text-gray-300 cursor-pointer ${currentPath === `/${language}/brokers` || currentPath.startsWith('/agent/') ? 'text-white' : ''} ? 'text-white' : ''}`}
          >
            {t('navigation.brokers')}
          </a>
          <a
            onClick={(e) => {
              e.preventDefault();
              navigate(`/${language}/about`);
            }}
            className={`hover:text-gray-300 cursor-pointer ${currentPath === `/${language}/about` ? 'text-white' : ''}`}
          >
            {t('navigation.about')}
          </a>
          {/* <div
            className="relative"
            onMouseEnter={() => setIsContactMenuVisible(true)}
            onMouseLeave={() => setIsContactMenuVisible(false)}
          > */}
          <a
            onClick={(e) => {
              e.preventDefault();
              navigate(`/${language}/contact`);
            }}
            className={`hover:text-gray-300 cursor-pointer gap-1 flex items-center ${currentPath === `/${language}/contact` ? 'text-white' : ''}`}
          >
            {t('navigation.contact')}

            {/* <IoIosArrowDown size={19} /> */}
          </a>

          <a
            onClick={(e) => {
              e.preventDefault();
              navigate(`/${language}/blog`);
            }}
            className={`hover:text-gray-300 cursor-pointer gap-1 flex items-center ${currentPath === `/${language}/blog` ? 'text-white' : ''}`}
          >
            {t('title.blog')}

            {/* <IoIosArrowDown size={19} /> */}
          </a>
          {/* </div> */}

        </nav>
      </div>


      {/* <div className='w-32 hidden lg:block '>
        {user ? (
          <><div className='flex items-center justify-end mr-3  '>
            <img src={user.photoUrl} alt="user" className="w-10 h-10 rounded-full cursor-pointer"
              onClick={() => setShowProfileMenu(!showProfileMenu)} />
          </div>
            <div className=''>
              {showProfileMenu && (
                <div className='absolute top-20 right-5  bg-white p-3 divide-y shadow-2xl rounded-lg z-30'>
                  {/* <a className='block text-gray-800 hover:text-gray-500 text-start  p-2'>
                    <CgProfile className='inline-block mr-2' />
                    {t('profile.00')}</a>
                  <a className='block text-gray-800 hover:text-gray-500 text-start p-2'>
                    <FaRegHeart className='inline-block mr-2' />
                    {t('profile.01')}</a> */}
      {/* <a className='block text-Red-600 hover:text-Red-600/80 text-start px-2 cursor-pointer' onClick={() => logOutConfirm()}>
                    <MdLogout className='inline-block mr-2' />
                    {t('profile.02')}</a>
                </div>
              )}
            </div></>

        ) : (
          <button className=" px-4 py-2 mr-2 bg-pantone288-500 border-yellow-500 border rounded-full hover:bg-white hover:text-yellow-500 text-white font-semibold text-sm transition duration-300"
            onClick={() => openLoginDialog()}>
            {t('signup.05')}
          </button>
        )
        } */}




      {/* </div> */}
      <div className="hidden lg:block">
        <LanguageSelector />
      </div>

      <LoginDialog isOpen={isLoginDialogOpen} onClose={closeLoginDialog} />

      {showLogoutConfirmation &&

        <div className='absolute z-30  h-full  flex items-center justify-center'>
          <LogoutConfirmationPopup
            onConfirm={logOut}
            onCancel={logOutConfirm}
          />
        </div>
      }
    </header>
  );
};

export default Header;
