import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FaStar, FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { getBrokerbyID } from '../services/brokrService';
import { getPropertyBybrokerId } from '../services/listingService';
import LoaderCard from '../components/loaderCard';
import { GrLocation } from 'react-icons/gr';
import { IoBedOutline } from 'react-icons/io5';
import { LiaBathSolid } from 'react-icons/lia';
import { BiArea } from 'react-icons/bi';
import { useLocation } from 'react-router-dom';
import { brokerEngData } from './brokerEngData';
import { brokersFrData } from './brokerFrData';
import { useTranslation } from 'react-i18next';

const BrokerSingle: React.FC = () => {
  const { id } = useParams();
  const [broker, setBroker] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [brokerListings, setBrokerListings] = useState([]);
  const location = useLocation();
  const { i18n } = useTranslation();
  const {t} = useTranslation();
  
  useEffect(() => {
    // console.log("useEffect is triggered with id:", id);
    fetchBrokerData();
    fetchListingsByBroker();
  }, [id, i18n.language]);

  useEffect(()=>{
    document.title = t('title.brokers')
  },[t])

  const fetchBrokerData = async () => {
    setLoading(true);
    try {
      const [prenom, nom]: any = id?.split('-');
      const brokerArray =
        i18n.language === 'fr'
          ? brokersFrData.filter((b) => b.PRENOM === prenom)
          : brokerEngData.filter((b) => b.PRENOM === prenom);
      
      setBroker(brokerArray);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchListingsByBroker = async () => {
    setLoading(true);
    try {
      const response = await getPropertyBybrokerId(id, 2, 2);
      setBrokerListings(response.data.inscriptions);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const formatPrice = (price: any) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    }).format(price);
  };

  if (loading) {
    return <div>Loading....</div>;
  }

  return (
    <div className="flex flex-col items-center p-8 bg-gray-100">
      {/* Broker Card */}
      <motion.div
        className="flex flex-col md:flex-row max-w-5xl bg-white shadow-lg rounded-lg overflow-hidden"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {/* Broker Image */}
        <div className="md:w-1/3 p-4">
          <img
            src={broker.PHOTO_URL}
            alt={`${broker.PRENOM} ${broker.NOM}`}
            className="rounded-lg w-full object-cover"
          />
        </div>

        {/* Broker Details */}
        <div className="md:w-2/3 p-6">
          <div className="flex items-center">
            <h1 className="text-2xl font-bold text-gray-800">
              {broker.PRENOM} {broker.NOM}
            </h1>
            <div className="flex ml-4 items-center text-yellow-500">
              {[...Array(5)].map((_, index) => (
                <FaStar
                  key={index}
                  className={index < broker.rating ? "text-yellow-500" : "text-gray-300"}
                />
              ))}
            </div>
            <a href="#reviews" className="text-blue-500 ml-2 underline text-sm">
              See all reviews
            </a>
          </div>

          <p className="text-lg font-medium text-gray-600">
            {broker.TITRE_PROFESSIONNEL || 'Real Estate Broker'} at{' '}
            <a href={broker.company_info.SITE_WEB} className="text-blue-500 underline">
              {broker.company_info.NOM_LEGAL}
            </a>
          </p>

          {/* Broker Information */}
          <div className="mt-4 space-y-2">
            <p className="text-gray-700">
              <span className="font-semibold">Agent License:</span> {broker.agentLicense}
            </p>
            <p className="text-gray-700">
              <span className="font-semibold">Tax Number:</span> {broker.taxNumber}
            </p>
            <p className="text-gray-700">
              <span className="font-semibold">Service Areas:</span> {broker.serviceAreas?.join(', ')}
            </p>
            <p className="text-gray-700">
              <span className="font-semibold">Specialties:</span> {broker.specialties?.join(', ')}
            </p>
          </div>

          {/* Action Buttons */}
          <div className="mt-6 flex space-x-4">
            <a
              href={`mailto:${broker.COURRIEL}`}
              className="flex items-center justify-center px-4 py-2 border rounded-lg text-green-500 border-green-500 hover:bg-green-500 hover:text-white"
            >
              <FaEnvelope className="mr-2" /> Send Email
            </a>
            <a
              href={`tel:${broker.TELEPHONE_1}`}
              className="flex items-center justify-center px-4 py-2 border rounded-lg text-green-500 border-green-500 hover:bg-green-500 hover:text-white"
            >
              <FaPhoneAlt className="mr-2" /> Call
            </a>
          </div>
        </div>
      </motion.div>

      {/* Listings Grid */}
      <div className='grid gap-y-16 grid-cols-4 gap-7 p-7 max-sm:grid-cols-1 max-md:grid-cols-2 xl:grid-cols-3 xl:pt-16 xl2:grid-cols-4 xl2:pt-6 max-lg:grid-cols-2 2xl:grid-cols-4 max-xl:grid-cols-3 place-items-center'>
        {loading ? (
          Array.from({ length: 3 }).map((_, index) => (
            <LoaderCard key={index} />
          ))
        ) : (
          
          brokerListings.map((item: any, index: number) => (
            <div key={index} className='w-[370px] max-2xl:w-[330px] max-sm:w-full rounded-xl shadow-lg'>
              <div className='h-[500px] max-2xl:h-[400px] relative bg-cover bg-center rounded-2xl overflow-hidden'>
                <img
                  src={item.images[0]}
                  alt='home'
                  className='rounded-2xl w-full h-full cursor-pointer object-cover transform hover:scale-105'
                  style={{
                    transition: 'all 1s cubic-bezier(.3,1,.35,1) 0s, transform .5s ease'
                  }}
                  onClick={() => window.open(`/property/${item.NO_INSCRIPTION}`, '_blank')}
                />
                <div className='p-4 rounded-b-xl absolute top-[64%] w-full'>
                  <div className='w-full bg-white px-5 py-3 flex flex-col rounded-xl gap-2 text-sm'>
                    <div className='flex justify-between items-center'>
                      <h2 className='text-lg text-yellow-500 font-bold whitespace-nowrap'>
                        {formatPrice(item.PRIX_LOCATION_DEMANDE ? item.PRIX_LOCATION_DEMANDE : item.PRIX_DEMANDE)}
                      </h2>
                      <p className='text-xs text-gray-600 font-semibold'>{item.class}</p>
                    </div>
                    <div className='flex gap-1 items-center'>
                      <GrLocation size={22} className='shrink-0' />
                      <h2 className='text-sm overflow-hidden text-ellipsis text-start font-normal'>
                        {`${item.NOM_RUE_COMPLET}, ${item.municipalities}, ${item.CODE_POSTAL}`}
                      </h2>
                    </div>
                    <div className='flex items-center justify-between'>
                      <div className='flex items-center gap-1 ml-1'>
                        <div className='flex gap-1 items-center'>
                          <IoBedOutline size={25} />
                          <h4>{item.NB_CHAMBRES ? item.NB_CHAMBRES : 0}</h4>
                        </div>
                        <span className='text-gray-300'>|</span>
                        <div className='flex gap-1 items-center'>
                          <LiaBathSolid size={25} />
                          <h4>{item.NB_SALLES_BAINS ? item.NB_SALLES_BAINS : 0}</h4>
                        </div>
                        <span className='text-gray-300'>|</span>
                        <div className='flex gap-1 items-center'>
                          <BiArea size={25} />
                          <h4 className='text-sm truncate w-32'>
                            {item.FACADE_TERRAIN && item.PROFONDEUR_TERRAIN
                              ? `${item.FACADE_TERRAIN}m x ${item.PROFONDEUR_TERRAIN}m | ${item.SUPERFICIE_TERRAIN} m²`
                              : '0m x 0m | 0 m²'}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default BrokerSingle;
